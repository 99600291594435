import { useEffect, useMemo } from 'react'

import { resolveButtonClasses } from 'components'

import { useExpertForm } from '../../hooks'

export const ExportButton = () => {
  const { values } = useExpertForm()

  const url = useMemo(
    () =>
      URL.createObjectURL(
        new Blob([JSON.stringify(values.current, null, 2)], {
          type: 'application/json',
        })
      ),
    [values]
  )

  useEffect(() => {
    return () => URL.revokeObjectURL(url)
  }, [url])

  return (
    <a href={url} className={resolveButtonClasses('secondary')} download="card">
      Експортувати в JSON
    </a>
  )
}
