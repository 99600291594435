import { Field as FormikField } from 'formik'
import { mapValues } from 'lodash'

import { FieldInput } from 'components/fields'

import { useIsFormDisabled } from '../../hooks'
import { FieldDocument } from '../FieldDocument'
import { FieldSelect } from '../FieldSelect'

import { FieldProps, NonNullableFieldProps } from './types'

export const Field = (props: FieldProps) => {
  const { field_name, screen_field_name, field_type, has_guide } =
    makeNonNullableProps(props)

  // By design: this specific field shouldn't be editable
  // @see https://utility.quantumobile.co/task?id=18380
  const isReadonlyField = field_name.includes('StudentScientist')
  const isDisabled = useIsFormDisabled() || isReadonlyField

  switch (field_type) {
    case 'LIST':
    case 'COLOR':
      return <FieldSelect field={props} disabled={isDisabled} />

    case 'BOOLEAN':
      return (
        <FieldInput
          type="checkbox"
          name={field_name}
          label={screen_field_name}
        />
      )

    case 'DOCUMENT':
      return (
        <FieldDocument
          name={field_name}
          label={screen_field_name}
          disabled={isDisabled}
        />
      )

    case 'MULTILINE':
      return (
        <FieldInput label={screen_field_name} name={field_name}>
          <FormikField as="textarea" name={field_name} />
        </FieldInput>
      )

    case 'DATE':
      return (
        <FieldInput type="date" name={field_name} label={screen_field_name} />
      )

    case 'NUMBER':
    case 'TEXT':
    case 'USERNAME':
      if (field_type === 'TEXT' && has_guide) {
        return <FieldSelect isCreatable field={props} disabled={isDisabled} />
      }
      return (
        <FieldInput
          type={field_type === 'NUMBER' ? 'number' : 'text'}
          name={field_name}
          label={screen_field_name}
        />
      )
    default:
      return null
  }
}

// ---

const makeNonNullableProps = (props: FieldProps) =>
  mapValues(props, x => (x === null ? undefined : x)) as NonNullableFieldProps
