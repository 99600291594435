import React, { CSSProperties, ReactNode } from 'react'

import clsx from 'clsx'

import styles from './styles.module.scss'

export interface IErrorMessageProps {
  children: ReactNode
  inline?: boolean
  bordered?: boolean
  className?: string
  style?: CSSProperties
}

export const ErrorMessage = React.memo(function ErrorMessage(
  props: IErrorMessageProps
) {
  const {
    children: error,
    inline = false,
    bordered = false,
    className,
    style,
  } = props

  const Tag = inline ? 'span' : 'div'
  const shouldShow = error !== undefined && error !== null && error !== ''

  return (
    <>
      {shouldShow && (
        <Tag
          className={clsx(className, styles.root, {
            [styles.bordered]: bordered,
          })}
          style={style}
        >
          {error}
        </Tag>
      )}
    </>
  )
})
