import { ReactChild, ReactFragment } from 'react'

import { useAppSelector } from 'hooks'

import { IRoleAccessOptions, useRoleAccess, useUser } from '../hooks'
import { selectIsGuestUser } from '../selectors'
import { IUser, UserGroup } from '../types'

interface UserRendererProps {
  children: ReactChild | ReactFragment | ((user: IUser) => ReactChild)
}

interface AuthOnlyProps
  extends UserRendererProps,
    Partial<IRoleAccessOptions> {}

export const AuthOnly = (props: AuthOnlyProps) => {
  const { roles, children } = props

  const isGuest = useAppSelector(selectIsGuestUser)

  if (isGuest) {
    return null
  }

  if (roles === undefined) {
    return <Renderer>{children}</Renderer>
  }

  return <RoleOnly {...(props as RoleOnlyProps)} />
}

// ---

interface RoleOnlyProps extends AuthOnlyProps {
  roles: UserGroup[]
}

const RoleOnly = (props: RoleOnlyProps) => {
  const { children, ...options } = props

  const isAccessAllowed = useRoleAccess(options)
  if (isAccessAllowed) {
    return <Renderer>{children}</Renderer>
  }

  return null
}

const Renderer = ({ children }: UserRendererProps) => {
  const user = useUser()
  return <>{typeof children === 'function' ? children(user) : children}</>
}
