import { Page } from 'components/layouts'

import { ExpertiseList } from 'features/ExpertiseList'

export function ExpertiseListPage() {
  return (
    <Page title="Перелік експертиз">
      <ExpertiseList />
    </Page>
  )
}
