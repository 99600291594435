import { Button, useConfirmModal } from 'components'

import { useAppDispatch } from 'hooks'

import { deleteFeedback } from '../../../../thunks'
import { ExpertiseFeedback } from '../../../../types'

export const DeleteButton = ({ id }: { id: ExpertiseFeedback['id'] }) => {
  const dispatch = useAppDispatch()

  const dialogConfirm = useConfirmModal({
    title: 'Ви впевнені, що дійсно бажаєте видалити?',
    btnCancelText: 'Скасувати',
    btnConfirmText: 'Видалити',
    onConfirm() {
      dispatch(deleteFeedback({ id }))
    },
  })

  return (
    <>
      <Button kind="secondary" onClick={dialogConfirm.open}>
        Видалити
      </Button>
      {dialogConfirm.$element}
    </>
  )
}
