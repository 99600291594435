import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { useAppSelector } from 'hooks'
import routes from 'routes'

import { createAuthRedirectLocation } from '../../HistoryReferrer'
import { IRoleAccessOptions, useRoleAccess } from '../hooks'
import { selectIsGuestUser } from '../selectors'

interface RoleRouteOptions {
  roles?: IRoleAccessOptions['roles']
  rolesMode?: IRoleAccessOptions['mode']
  rolesMatch?: IRoleAccessOptions['match']
}

// ---

interface AuthRouteProps extends RouteProps, RoleRouteOptions {}

export const AuthRoute = (props: AuthRouteProps) => {
  const { roles, rolesMode, rolesMatch, ...rest } = props

  const isGuest = useAppSelector(selectIsGuestUser)

  if (isGuest) {
    return <AuthRedirect />
  }

  if (roles === undefined) {
    return <Route {...rest} />
  }

  return <RoleRoute {...(props as RoleRouteProps)} />
}

// ---

interface RoleRouteProps extends AuthRouteProps {
  roles: NonNullable<AuthRouteProps['roles']>
}

const RoleRoute = (props: RoleRouteProps) => {
  const { roles, rolesMode: mode, rolesMatch: match, ...rest } = props
  const isAccessAllowed = useRoleAccess({ roles, mode, match })
  const isGuest = useAppSelector(selectIsGuestUser)

  if (isAccessAllowed) {
    return <Route {...rest} />
  }

  if (isGuest) {
    return <AuthRedirect />
  }

  // If we're authorized, but still have no access to page –
  // there is nothing more we can do. Just redirect to home page.
  return <Redirect to={routes.home} />
}

// ---

function AuthRedirect() {
  const loc = useLocation()
  return <Redirect to={createAuthRedirectLocation(loc)} />
}
