import { Flex } from 'components/layouts'

import { selectIsModeratorUser } from 'features/User'

import { useAppSelector } from 'hooks'

import { selectExpertForm } from '../../selectors'

import { CardStatusButtons } from './CardStatusButtons'

import styles from './styles.module.scss'

export function Header() {
  const isModerator = useAppSelector(selectIsModeratorUser)
  if (!isModerator) return null

  return (
    <Flex className={styles.header} center="v">
      <CurrentStatus />
      <CardStatusButtons />
    </Flex>
  )
}

function CurrentStatus() {
  const { formStatus: status } = useAppSelector(selectExpertForm)
  return (
    <div className={styles.card_status}>
      <b>Статус картки особи</b>
      <div className={styles.card_status_value}>{status}</div>
    </div>
  )
}
