import { useCallback, useMemo } from 'react'

import { produce } from 'immer'
import { flatten } from 'lodash'

import { Button } from 'components'

import { canDeactivateField, traverseFields } from '../../lib'

import { useFiltersForm } from './lib'

export function BtnDisableAll() {
  const { setValues, values, submitForm } = useFiltersForm()
  const disableAll = useCallback(() => {
    setValues(values =>
      produce(values, values => {
        traverseFields(values, field => {
          if (canDeactivateField(field)) {
            field.enabled = false
          }
          if (field.type === 'checkbox') {
            field.value = false
          }
        })
      })
    )
    submitForm()
  }, [setValues, submitForm])

  const disabled = useMemo(() => {
    const disabledState = traverseFields(values, field => {
      if (canDeactivateField(field)) {
        return field.enabled === false
      }
      if (field.type === 'checkbox') {
        return field.value === false
      }
      return undefined
    })
    return flatten(Object.values(disabledState)).every(disabled => disabled)
  }, [values])

  return (
    <Button kind="secondary" disabled={disabled} onClick={disableAll}>
      Скинути усі фільтри
    </Button>
  )
}
