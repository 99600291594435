import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQuery } from 'api'

import { SystemDictionaries, SystemDictionary } from './types'

const api = createApi({
  reducerPath: 'dicts',

  baseQuery: axiosBaseQuery({ baseUrl: `/fields_def/dictionaries` }),

  endpoints: builder => ({
    getDicts: builder.query<SystemDictionaries, void>({
      query: () => '/',
      transformResponse: (raw: SystemDictionary[]) =>
        raw.reduce((m, dict) => {
          m[dict.name] = dict.pairs
          return m
        }, {} as SystemDictionaries),
    }),

    getDict: builder.query<SystemDictionary['pairs'], string>({
      query: name => name,
      transformResponse: (raw: SystemDictionary) => raw.pairs,
    }),
  }),
})

export const DictionariesAPI = api
