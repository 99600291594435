import { createAsyncThunk } from '@reduxjs/toolkit'

import { IExpertise, normalizeExpertise } from 'features/ExpertiseList'
import { RegistryAPI } from 'features/PersonsRegistry'
import { isExpertUser, selectUser } from 'features/User'

import * as API from 'api'

import { ExpertCabinetSliceState } from './types'

export const getExpertise = createAsyncThunk(
  'ExpertCabinet/getExpertise',
  async (_, api): Promise<IExpertise[]> => {
    const { getState, dispatch } = api
    const state = getState() as RootState
    const user = selectUser(state)

    if (isExpertUser(user)) {
      const {
        data: { fields },
      } = await API.expertForm.getUserCard(user.card.id)

      // `IExpertise` is described based on values from `fields['Експертиза']`.
      // If fields list will change, we'll have to update code (columns config in table) anyway.
      // So this forced assertion here is ok.
      let expertise = fields['Експертиза'] as unknown as IExpertise[]
      expertise = expertise.filter(x => x.IdEdboOp)
      return await dispatch(normalizeExpertise(expertise)).unwrap()
    }

    return []
  }
)

export const getIntegralScore = createAsyncThunk(
  'ExpertCabinet/getIntegralScore',
  async (
    _,
    { getState }
  ): Promise<ExpertCabinetSliceState['integral_score']> => {
    const state = getState() as RootState
    const user = selectUser(state)

    if (isExpertUser(user)) {
      const record = await RegistryAPI.getPersonRecord(user.card.id)
      return record.score
    }

    return null
  }
)
