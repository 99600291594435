import clsx from 'clsx'

import { GoBackButton } from 'features/HistoryReferrer'

import { Flex } from '../Flex'

import { IPageProps } from './types'

import styles from './styles.module.scss'

export default function Page(props: IPageProps) {
  const { title, children, center = false, showBackButton = false } = props
  return (
    <div
      className={clsx(styles.page, {
        [styles.page_center]: center === true,
        [styles.page_center_v]: center === 'v',
        [styles.page_center_h]: center === 'h',
      })}
    >
      <Flex center="v" className={styles.page_header}>
        {showBackButton && <GoBackButton />}
        {title !== undefined && <h2 className={styles.page_title}>{title}</h2>}
      </Flex>
      <div className={styles.page_content}>{children}</div>
    </div>
  )
}
