import { generatePath } from 'react-router'

import { GuestOnly } from 'features/User'

import routes from 'routes'

import { Charts } from './components/Charts'
import { Contacts } from './components/Contacts'
import { LandingLinkButton } from './components/LinkButton'

import styles from './styles.module.scss'

export const Landing = () => {
  return (
    <main className={styles.main}>
      <div className={styles.main_block}>
        <GuestOnly>
          <LandingLinkButton
            link={routes.login}
            description="Вхід до системи для експертів, членів та працівників Національного
              агентства"
          >
            Вхід
          </LandingLinkButton>
          <LandingLinkButton
            link={generatePath(routes.documents)}
            description="Подати заявку на участь у конкурсному відборі експертів з
            акредитації освітніх програм з числа науково-педагогічних
            працівників або здобувачів вищої освіти"
          >
            Подати заявку
          </LandingLinkButton>
        </GuestOnly>

        <Contacts />
      </div>
      <Charts />
    </main>
  )
}
