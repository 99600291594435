import {
  selectIsModeratorUser,
  selectIsSupervisorUser,
  selectUser,
} from 'features/User'

import { useAppSelector } from 'hooks'

import { ExpertiseFeedback } from '../../../../types'
import { ReviewForm } from '../ReviewForm'

import styles from './styles.module.scss'

interface ReviewAddedProps {
  feedback: ExpertiseFeedback
  toExpert: number
}

export const ReviewAdded = (props: ReviewAddedProps) => {
  const isModerator = useAppSelector(selectIsModeratorUser)
  const isSupervisor = useAppSelector(selectIsSupervisorUser)
  const { id } = useAppSelector(selectUser)
  const { feedback, toExpert } = props

  return (
    <Choose>
      <When
        condition={isModerator && !feedback.approved && !feedback.toberemoved}
      >
        <div className={styles.wrapper}>
          <div>Додається новий відгук</div>
        </div>
      </When>
      <Otherwise>
        <ReviewForm
          feedback={feedback}
          expertId={toExpert}
          removable={isSupervisor || feedback.feedback_author === id}
        />
      </Otherwise>
    </Choose>
  )
}
