import { createAsyncThunk } from '@reduxjs/toolkit'

import * as API from './api'
import { traverseFields } from './lib'
import { RegistryFiltersFormValues, RegistryFiltersQuery } from './types'

export const fetchRegistry = createAsyncThunk(
  'PersonsRegistry/fetch',
  (filters?: RegistryFiltersFormValues) => {
    const activeFilters = traverseFields(filters, field => {
      const enabled =
        field.type === 'checkbox' ? field.value === true : field.enabled

      if (enabled) {
        return field.value
      }
    }) as Partial<RegistryFiltersQuery>

    return API.getRegistry(activeFilters)
  }
)
