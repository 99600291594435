import React from 'react'

import { useField } from 'formik'

import { FieldInput } from 'components/fields'

import { useOnMount } from 'hooks'

import { isCheckboxFieldName } from '../../lib'
import { IRegistryFilterField, RegistryFilterName } from '../../types'

import { FilterFieldContext, useFiltersForm } from './lib'
import {
  FilterFieldArrayProps,
  FilterFieldProps,
  IFilterFieldContext,
} from './types'

import styles from './field.module.scss'

export function FilterField(props: FilterFieldProps) {
  const { children, label, name, forceEnabled = false, style } = props

  const isCheckbox = isCheckboxFieldName(name)
  const denyDisable = isCheckbox || forceEnabled

  const valueFieldName = `${name}.value`
  const enabledFieldName = `${name}.enabled`

  const [{ value: enabled } /*, meta*/, , { setValue: setEnabled }] =
    useField<IRegistryFilterField['enabled']>(enabledFieldName)

  const disabled = !enabled

  // ---

  const { setFieldValue } = useFiltersForm()

  useOnMount(() => {
    if (denyDisable) {
      setFieldValue(`${name}.canDisable`, false)

      // Make sure field will be enabled, even if you messed up with initial state
      if (disabled) {
        setEnabled(true)
      }
    }

    if (isCheckbox) {
      const type: IRegistryFilterField['type'] = 'checkbox'
      setFieldValue(`${name}.type`, type)
    }
  })

  // ---

  const ctx: IFilterFieldContext = {
    name: valueFieldName,
    disabled,
  }

  const child = React.Children.only(
    typeof children === 'function' ? children(ctx) : children
  )

  return (
    <div className={styles.filter_field} style={style}>
      {!denyDisable && (
        <FieldInput
          type="checkbox"
          name={enabledFieldName}
          label={label}
          labelClassName={styles.label}
        />
      )}

      <FilterFieldContext.Provider value={ctx}>
        {React.cloneElement(child, {
          disabled: !enabled,
          name: valueFieldName,
          // If enabled/disabled checkbox is not available,
          // underlying component should deal with label on it's own
          label: denyDisable ? (
            <span className={styles.label}>{label}</span>
          ) : (
            child.props.label
          ),
        })}
      </FilterFieldContext.Provider>
    </div>
  )
}

export function FilterFieldArray(props: FilterFieldArrayProps) {
  const { name } = props
  return <FilterField {...props} name={name as RegistryFilterName} />
}
