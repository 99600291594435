import React from 'react'
import { useHistory } from 'react-router'

import { Button, ErrorMessage, Modal, useConfirmModal } from 'components'
import { Flex } from 'components/layouts'

import { useAppDispatch, useAppSelector, useConfirm } from 'hooks'
import routes from 'routes'

import { selectExpertForm } from '../../selectors'
import { resetError } from '../../slice'

interface SendToReviewButtonProps {
  onConfirm: () => void
}

export function SendToReviewButton(props: SendToReviewButtonProps) {
  const { onConfirm } = props

  const dispatch = useAppDispatch()
  const history = useHistory()
  const { error } = useAppSelector(selectExpertForm)

  const dialogResult = useConfirm({
    onClose() {
      if (error) {
        dispatch(resetError())
      }
    },
    onConfirm() {
      // unconditional redirect, assuming this component is only shown to proper users
      history.push(routes.home)
    },
  })

  const dialogConfirm = useConfirmModal({
    onConfirm,
    onConfirmFinally: dialogResult.open,
    title:
      'Ви дійсно бажаєте відправити анкету на перевірку? Цю дію неможливо буде відмінити',
    btnCancelText: 'Ні',
    btnConfirmText: 'Відправити',
  })

  return (
    <>
      <Button kind="secondary" onClick={dialogConfirm.open}>
        Надіслати заявку на перевірку
      </Button>

      {dialogConfirm.$element}

      <If condition={dialogResult.isOpen}>
        <Choose>
          <When condition={error !== undefined}>
            <Modal title="Помилка">
              <Flex column center gap={20}>
                <ErrorMessage>{error}</ErrorMessage>
                <Button onClick={dialogResult.cancel}>OK</Button>
              </Flex>
            </Modal>
          </When>

          <Otherwise>
            <Modal title="Анкету успішно відправлено на перевірку">
              <Button onClick={dialogResult.confirm}>OK</Button>
            </Modal>
          </Otherwise>
        </Choose>
      </If>
    </>
  )
}
