import clsx from 'clsx'

import { ErrorMessage } from '../../ErrorMessage'

import { ControlProps } from './types'

import styles from './styles.module.scss'

export const Control = (props: ControlProps) => {
  const {
    label,
    containerStyle,
    containerClassName,
    labelStyle,
    labelClassName,
    children,
    error,
    type,
  } = props

  const isSwitch = type === 'checkbox' || type === 'radio'

  return (
    <label
      className={clsx(containerClassName, styles.container)}
      style={containerStyle}
    >
      <div
        className={clsx(styles.input_block, {
          [styles.switch_block]: isSwitch,
        })}
      >
        {label && (
          <span
            className={clsx(styles.label, labelClassName)}
            style={labelStyle}
          >
            {label}
          </span>
        )}
        {children}
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </label>
  )
}
