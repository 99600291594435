import { selectIsModeratorUser } from 'features/User'

import { useAppSelector } from 'hooks'

import { useExpertForm } from '../../hooks'

import { AddedEntry, GroupEntry } from './components/GroupEntry'
import { getGroupParams, useAddedDraftEntries, useFieldGroups } from './lib'
import { FieldGroupProps, FieldGroupsListProps } from './types'

export function FieldGroupsList(props: FieldGroupsListProps) {
  const { fields } = props
  const groups = useFieldGroups(fields)
  return (
    <>
      {groups.map((group, i) => (
        <FieldsGroup group={group} key={i} />
      ))}
    </>
  )
}

function FieldsGroup(props: FieldGroupProps) {
  const { group } = props
  const { group_name } = getGroupParams(group)
  const {
    values: {
      current: { [group_name]: values },
    },
  } = useExpertForm()

  const isModerator = useAppSelector(selectIsModeratorUser)
  const addedEntries = useAddedDraftEntries(group_name)

  return (
    <fieldset>
      {/* By design: added entries should always be at the very top */}
      {isModerator &&
        addedEntries.map(fieldset => (
          <AddedEntry
            key={fieldset.id as string}
            id={fieldset.id as string}
            group={group}
          />
        ))}

      {values.map(fieldset => (
        <GroupEntry
          key={fieldset.id as string}
          id={fieldset.id as string}
          group={group}
        />
      ))}
    </fieldset>
  )
}
