import React, { useMemo } from 'react'

import { AnySelectOption, SelectOption } from 'components/Select'
import {
  FieldInput,
  FieldInputProps,
  FieldSelect,
  FieldSelectProps,
} from 'components/fields'

import {
  FieldSelectDict,
  FieldSelectDictProps,
  SystemDictionaryName,
} from 'features/Dicts'

import { ExpertFormStatus as ExpertCardStatus, ExpertFormStatus } from 'types'

import { BoolSelectValue } from '../../types'

import { useFilterField } from './lib'
import { IFilterFieldContext } from './types'

export function Checkbox(
  props: Omit<FieldInputProps, 'type' | keyof IFilterFieldContext>
) {
  return <FieldInput {...props} type="checkbox" {...useFilterField()} />
}

export function Select<T extends AnySelectOption = SelectOption>(
  props: Omit<FieldSelectProps<T>, keyof IFilterFieldContext>
) {
  return (
    <FieldSelect
      styles={{
        container: base => ({
          ...base,
          width: 'unset',
          maxWidth: 300,
        }),
      }}
      {...props}
      {...useFilterField()}
    />
  )
}

export function SelectDict(
  props: Omit<FieldSelectDictProps, 'batch' | keyof IFilterFieldContext>
) {
  return (
    <FieldSelectDict
      styles={{
        container: base => ({
          ...base,
          width: 280,
        }),
      }}
      {...props}
      {...useFilterField()}
      batch
    />
  )
}

export function SelectExpertCardStatus() {
  return (
    <Select
      options={[
        { value: ExpertCardStatus.new, label: 'Нова' },
        { value: ExpertFormStatus.sent, label: 'Відправлено' },
        { value: ExpertFormStatus.candidate, label: 'Кандидат' },
        { value: ExpertFormStatus.expert, label: 'Експерт' },
        { value: ExpertFormStatus.rejected, label: 'Відхилено' },
        { value: ExpertFormStatus.removed, label: 'Вилучено' },
      ]}
      styles={{
        container: base => ({
          ...base,
          width: 160,
        }),
      }}
    />
  )
}

export function SelectZVO() {
  return (
    <SelectDict
      dict={SystemDictionaryName.edu_institutions}
      wrapperStyle={{ flexGrow: 1 }}
      styles={{
        container: base => ({
          ...base,
          width: '100%',
        }),
      }}
    />
  )
}

export function SelectBool<Nullable extends boolean = false>(props: {
  nullable?: Nullable
}) {
  const { nullable = false } = props

  type Option = SelectOption<BoolSelectValue<Nullable>>

  const options = useMemo(() => {
    const options: Option[] = [
      { value: false, label: 'Ні' },
      { value: true, label: 'Так' },
    ]
    if (nullable) {
      options.push({
        value: 'unknown',
        label: 'Не встановлено',
      } as Option)
    }
    return options
  }, [nullable])

  return (
    <Select<Option>
      options={options}
      isSearchable={false}
      styles={{
        container: base => ({
          ...base,
          width: nullable ? 190 : 90,
        }),
      }}
    />
  )
}

export function InputScore() {
  return (
    <FieldInput
      type="number"
      min={10}
      max={100}
      step={1}
      style={{ width: 80 }}
      {...useFilterField()}
    />
  )
}

export function InputDate() {
  return <FieldInput type="date" style={{ width: 180 }} {...useFilterField()} />
}

export function InputSearch() {
  return (
    <FieldInput
      placeholder="Пошук осiб..."
      style={{
        maxWidth: 'unset',
        height: 48,
      }}
      containerStyle={{
        width: '100%',
      }}
      {...useFilterField()}
    />
  )
}
