import { Button, Form, FormSubmitError } from 'components'
import { FieldInput } from 'components/fields'

import { getFormFields } from 'features/ExpertForm'

import { useAppDispatch, useOnMount } from 'hooks'

import { OccupationFields } from './components/OccupationFields'
import { useSubmitHandler } from './hooks'
import { FormSchema, initialValues } from './lib'

import styles from './styles.module.scss'

export const Registration = () => {
  const dispatch = useAppDispatch()

  const submitForm = useSubmitHandler()

  useOnMount(() => {
    dispatch(getFormFields())
  })

  return (
    <main className={styles.main}>
      <h1 className={styles.header}>
        Для створення заявки, введіть, будь ласка, наступні дані
      </h1>

      <Form
        className={styles.form}
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={FormSchema}
      >
        {({ isValid }) => (
          <>
            <FieldInput type="text" label="Прізвище" name="LName" />
            <FieldInput type="text" label="Ім'я" name="FName" />
            <FieldInput type="text" label="По батькові" name="MName" />
            <FieldInput
              type="text"
              label="Електронна адреса"
              name="EmailAddr"
            />

            <OccupationFields />

            <FormSubmitError bordered />

            <div className={styles.button_wrapper}>
              <Button type="submit" kind="primary" disabled={!isValid}>
                Cтворити заявку
              </Button>
            </div>
          </>
        )}
      </Form>
    </main>
  )
}
