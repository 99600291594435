import { useMemo } from 'react'

import { getIn } from 'formik'
import { mapValues, pick } from 'lodash'
import { v4 as uuid } from 'uuid'

import { selectIsModeratorUser } from 'features/User'

import { FILE_DEFAULT_NAME } from 'const'
import { useAppSelector } from 'hooks'
import {
  ExpertFormDraftChanges,
  ExpertFormFieldSchema,
  ExpertFormValues,
  FieldSetDataSource,
  FormFieldsGroup,
  FormFieldsSet,
} from 'types'

import { useExpertForm } from '../../hooks'
import { getFieldGroupName } from '../../lib'

import { FieldGroup, FieldGroupParams } from './types'

export function useFieldGroups(
  fields: ExpertFormFieldSchema[]
): Array<ExpertFormFieldSchema[]> {
  // It's assumed here, that all fields in list are sorted accordingly to groups they belong
  // That is, successive items with the same group name do form a group.
  // Two fields with the same group name, which are NOT successive, do form a TWO DIFFERENT groups with the same name
  // TODO:
  //  will this approach be maintained when new fields will be added to DB?
  //  shouldn't we instead make Group a separate entity { id, name, order }, and relate it to every field?
  return useMemo(
    () =>
      fields.reduce((groups, field, i, fields) => {
        if (groups.length === 0) groups.push([])
        if (i > 0) {
          const { screen_group_name: curr } = field
          const { screen_group_name: prev } = fields[i - 1]

          /* By discussion: if field belongs to a nameless group,
           * consider it a separate group with a single field. */
          const isNameless = curr === null || curr === ''

          if (curr !== prev || isNameless) groups.push([])
        }
        const group = groups[groups.length - 1]
        group.push(field)
        return groups
      }, [] as Array<ExpertFormFieldSchema[]>),
    [fields]
  )
}

export const resetFields = (fields: FormFieldsSet) => {
  return mapValues(fields, (v, k) => {
    if (typeof v === 'object') {
      const docValues = pick(v, [
        'doc_filename',
        'doc_type',
        'doc_src',
        'doc_name',
      ])
      docValues.doc_filename = FILE_DEFAULT_NAME
      return docValues
    }
    return k === 'id' ? uuid() : ''
  })
}

export function getGroupParams(group: FieldGroup): FieldGroupParams {
  /* This implicitly relies on our grouping logic (see `useFieldGroups`).
   * It assumes that:
   *  – every field in group has the same group-related props
   *    (so we can take any of them as sample)
   *  – each field from a nameless group is considered a separate group with a single field
   *    (so `field_name` prop is guaranteed to be unique) */
  const [sample] = group

  const group_name = getFieldGroupName(sample)
  const { screen_group_name, repeat_group } = sample
  return {
    group_name,
    screen_group_name,
    repeat_group,
  }
}

// ---

function useFormValues(
  dataSource: FieldSetDataSource = 'current'
): ExpertFormValues {
  const { values: allValues } = useExpertForm()
  return getIn(allValues, dataSource)
}

function useDraftValues(
  section: keyof ExpertFormDraftChanges
): ExpertFormValues {
  const values = useFormValues(`changed.${section}`)
  const isModerator = useAppSelector(selectIsModeratorUser)
  return isModerator ? values : {}
}

function useDraftGroup(
  section: keyof ExpertFormDraftChanges,
  groupName: string
): FormFieldsGroup | undefined {
  return useDraftValues(section)[groupName]
}

export function useDraftEntry(
  section: keyof ExpertFormDraftChanges,
  groupName: string,
  fieldsetId: string
): FormFieldsSet | undefined {
  const group = useDraftValues(section)[groupName]
  return group?.find(x => x.id === fieldsetId)
}

export function useAddedDraftEntries(groupName: string): FormFieldsGroup {
  return useDraftGroup('tobeadded', groupName) ?? []
}

export function useChangedDraftEntry(
  groupName: string,
  fieldsetId: string
): FormFieldsSet | undefined {
  return useDraftEntry('tobemodified', groupName, fieldsetId)
}

export function useRemovedDraftEntry(
  groupName: string,
  fieldsetId: string
): FormFieldsSet | undefined {
  return useDraftEntry('toberemoved', groupName, fieldsetId)
}

export function useEntryIndex(
  dataSource: FieldSetDataSource,
  groupName: string,
  fieldsetId: string
): number {
  const values = useFormValues(dataSource)
  const groupValues = values[groupName]
  const entryIndex = groupValues?.findIndex(x => x.id === fieldsetId) ?? -1
  return entryIndex
}
