import { ReactNode, useState } from 'react'

import clsx from 'clsx'

import { Button, useConfirmModal } from 'components'
import { Control } from 'components/fields'

import styles from './styles.module.scss'

interface DraftModeratorControlsProps {
  onSubmit?: () => void
  onReject: (value: string) => void
  title: ReactNode
  className?: string
  isDisable?: boolean
}

export function DraftModeratorControls(props: DraftModeratorControlsProps) {
  const { onSubmit, onReject, title, className, isDisable = false } = props
  const [rejectionReason, setRejectionReason] = useState('')

  const hasError = rejectionReason.trim() === ''
  const dialogReject = useConfirmModal({
    title,
    btnCancelText: 'Закрити',
    btnConfirmText: 'Відмовити',
    allowConfirm: !hasError,
    content: (
      <div className={styles.dialog_reject_draft}>
        <Control label="Обґрунтування відмови:">
          <textarea
            value={rejectionReason}
            onChange={e => setRejectionReason(e.currentTarget.value)}
            placeholder="У випадку відмови від зміни, будь ласка, напишіть обґрунтування відмови"
          />
        </Control>
      </div>
    ),
    onConfirm() {
      onReject(rejectionReason)
    },
  })

  return (
    <div className={clsx(styles.controls, className)}>
      {onSubmit && (
        <Button onClick={onSubmit} disabled={isDisable}>
          Погодити
        </Button>
      )}
      <Button onClick={dialogReject.open} disabled={isDisable}>
        Відмовити
      </Button>
      {dialogReject.$element}
    </div>
  )
}
