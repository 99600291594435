import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  DictionariesAPI,
  SystemDictionary,
  SystemDictionaryName,
} from 'features/Dicts'

import { getExpertiseList } from 'api/Expertise'

import { IExpertise } from './types'

export const getExpertise = createAsyncThunk(
  'ExpertiseList/getExpertise',
  async (
    needModeration: boolean | undefined,
    { dispatch }
  ): Promise<IExpertise[]> => {
    const { data } = await getExpertiseList(needModeration)
    return await dispatch(normalizeExpertise(data)).unwrap()
  }
)

export const normalizeExpertise = createAsyncThunk(
  'ExpertiseList/normalizeExpertise',
  async (expertise: IExpertise[], { dispatch }) => {
    // Get labels for dictionary fields
    const [dict_institutions, dict_degree, specialities_edu] =
      await Promise.all([
        dispatch(getDict(SystemDictionaryName.edu_institutions)).unwrap(),
        dispatch(getDict(SystemDictionaryName.edu_degree)).unwrap(),
        dispatch(getDict(SystemDictionaryName.specialities_edu)).unwrap(),
      ])

    return expertise.map(x => ({
      ...x,
      ExpZVO: dict_institutions[x.ExpZVO],
      ExpEduProgramLevel: dict_degree[x.ExpEduProgramLevel],
      ExpEduSpec: specialities_edu[x.ExpEduSpec],
    }))
  }
)

const getDict = createAsyncThunk(
  'ExpertiseList/getExpertiseDicts',
  async (name: SystemDictionaryName, { dispatch, getState }) => {
    const endpoint = DictionariesAPI.endpoints.getDict
    const subscription = dispatch(endpoint.initiate(name))
    await subscription
    subscription.unsubscribe()
    const selector = endpoint.select(name)
    const { data } = selector(getState() as RootState)

    if (data === undefined) {
      throw new Error(`Failed to load dictionary: ${name}`)
    }

    return data as SystemDictionary['pairs']
  }
)
