import { createSlice } from '@reduxjs/toolkit'

import { addAsyncThunkReducers } from 'utils'

import * as thunks from './thunks'
import { IUser, IUserState } from './types'

export * from './thunks'

const GUEST_USER: IUser = {
  id: undefined,
  email: '',
  first_name: '',
  last_name: '',
  groups: [],
  card: {
    id: null,
  },
}

const initialState: IUserState = {
  loading: false,
  error: undefined,
  data: GUEST_USER,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {},

  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.getCurrentUser, 'data')
    addAsyncThunkReducers(builder, thunks.logout, state => {
      state.data = GUEST_USER
    })
  },
})
