import { connectRouter, routerMiddleware } from 'connected-react-router'

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { DictionariesAPI } from 'features/Dicts'
import { ExpertCabinetSlice } from 'features/ExpertCabinet'
import expertFormReducer from 'features/ExpertForm/slice'
import { ExpertiseListSlice } from 'features/ExpertiseList'
import { ExpertiseReviewsSlice } from 'features/ExpertiseReviews'
import { HistoryReferrerSlice } from 'features/HistoryReferrer'
import { PersonsRegistrySlice } from 'features/PersonsRegistry'
import { UserSlice } from 'features/User'

import { history } from 'routes'

const rootReducer = combineReducers({
  router: connectRouter(history),
  referrer: HistoryReferrerSlice.reducer,
  expertForm: expertFormReducer,
  personsRegistry: PersonsRegistrySlice.reducer,
  [DictionariesAPI.reducerPath]: DictionariesAPI.reducer,
  user: UserSlice.reducer,
  expertCabinet: ExpertCabinetSlice.reducer,
  expertiseList: ExpertiseListSlice.reducer,
  expertiseReviews: ExpertiseReviewsSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      routerMiddleware(history),
      DictionariesAPI.middleware
    ),
})

setupListeners(store.dispatch)

declare global {
  type RootState = ReturnType<typeof store.getState>
  type AppDispatch = typeof store.dispatch
  type AppSelector<T> = (state: RootState) => T
}

// With this, `useSelector(state => ...)` automatically infers `state` param as `AppState`
declare module 'react-redux' {
  interface DefaultRootState extends RootState {}
}
