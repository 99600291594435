import {
  isExpertUser,
  isGuestUser,
  isModeratorUser,
  isSupervisorUser,
} from './lib'
import { IUser } from './types'

export const selectUser: AppSelector<IUser> = state => state.user.data

export const selectIsGuestUser: AppSelector<boolean> = state =>
  isGuestUser(selectUser(state))

export const selectIsAuthorized: AppSelector<boolean> = state =>
  !selectIsGuestUser(state)

export const selectIsExpertUser: AppSelector<boolean> = state =>
  isExpertUser(selectUser(state))

export const selectIsModeratorUser: AppSelector<boolean> = state =>
  isModeratorUser(selectUser(state))

export const selectIsSupervisorUser: AppSelector<boolean> = state =>
  isSupervisorUser(selectUser(state))
