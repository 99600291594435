import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { addAsyncThunkReducers } from 'utils'

import * as thunks from './thunks'
import { ExpertiseListSliceFilters, ExpertiseListSliceState } from './types'

const initialState: ExpertiseListSliceState = {
  expertise: [],
  loading: false,
  error: undefined,
  filters: {
    search: '',
    needModeration: false,
  },
}

export const ExpertiseListSlice = createSlice({
  name: 'ExpertList',
  initialState,

  reducers: {
    setFilters(state, action: PayloadAction<ExpertiseListSliceFilters>) {
      state.filters = action.payload
    },
  },

  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.getExpertise, 'expertise')
  },
})

export const { setFilters } = ExpertiseListSlice.actions
