import { Flex } from 'components/layouts'

import { useAppDispatch, useAppSelector, useOnMount } from 'hooks'

import { fetchRegistry } from '../../thunks'
import { RegistryFiltersForm } from '../Filters'
import { RegistryTable } from '../RegistryTable'

import styles from './styles.module.scss'

export function PersonsRegistry() {
  const dispatch = useAppDispatch()

  useOnMount(() => {
    dispatch(fetchRegistry())
  })

  const items = useAppSelector(x => x.personsRegistry.items)

  return (
    <Flex column className={styles.registry}>
      <RegistryFiltersForm />
      <RegistryTable data={items} />
    </Flex>
  )
}
