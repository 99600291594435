import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAppSelector } from 'hooks'
import routes from 'routes'

import { usePrevLocation } from '../../HistoryReferrer'
import { selectIsAuthorized } from '../selectors'

export function GuestRoute(props: RouteProps) {
  const isAuthorized = useAppSelector(selectIsAuthorized)
  const prev = usePrevLocation()
  if (isAuthorized) {
    return <Redirect to={prev ?? routes.home} />
  } else {
    return <Route {...props} />
  }
}
