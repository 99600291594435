import { AxiosResponse } from 'axios'
import { serialize } from 'object-to-formdata'

import {
  ApplyChangePayload,
  ExpertFormDocumentSchema,
  ExpertFormFieldSchema,
  ExpertFormPayload,
  ExpertFormResponse,
  ExpertFormStatus,
} from 'types'

import axiosInstance from './api'

const PATHS = {
  fieldsSchema: '/fields_def/fields',
  fields: '/cards',
  documents: '/fields_def/documents',
  expertise: '/cards/expertise',
}

export const getFieldsSchema = () =>
  axiosInstance.get<ExpertFormFieldSchema[]>(PATHS.fieldsSchema)

export const submitFields = (data: ExpertFormPayload) => {
  if (data.id) {
    return axiosInstance.patch<ExpertFormResponse>(
      `${PATHS.fields}/${data.id}/`,
      data
    )
  } else {
    return axiosInstance.post<ExpertFormResponse>(`${PATHS.fields}/`, data)
  }
}

export const updateStatus = async (
  data: Pick<Required<ExpertFormPayload>, 'id' | 'status'>
) => {
  const { id, status } = data
  const response = await axiosInstance.patch<ExpertFormResponse>(
    `${PATHS.fields}/${id}/`,
    {
      status,
    }
  )

  // skip all the rest of data, as it's not related to status updating
  Object.assign(response, {
    data: response.data.status,
  })

  return response as unknown as AxiosResponse<ExpertFormStatus>
}

export const deleteForm = async (id: string) => {
  await axiosInstance.delete(`${PATHS.fields}/${id}/`)
}

export const getUserCard = (id: string) =>
  axiosInstance.get<ExpertFormResponse>(`${PATHS.fields}/${id}/`)

interface DocumentPayload {
  doc_file: File
  doc_type: string
  doc_src: string
  doc_desc?: string
}

export const submitDocument = (data: DocumentPayload, id?: number) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const payload = serialize(data)
  if (id) {
    return axiosInstance.patch<ExpertFormDocumentSchema>(
      `${PATHS.documents}/${id}/`,
      payload,
      { headers }
    )
  } else {
    return axiosInstance.post<ExpertFormDocumentSchema>(
      `${PATHS.documents}/`,
      payload,
      { headers }
    )
  }
}

export const applyChange = (data: ApplyChangePayload, id: string) => {
  return axiosInstance.post<ExpertFormResponse>(
    `${PATHS.fields}/${id}/apply_change/`,
    data
  )
}
