import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { RequiredProps } from 'types/utils'

export const BASE_URL = '/api'

const axiosApi = axios.create({
  baseURL: BASE_URL,

  // Django
  // @see https://docs.djangoproject.com/en/3.2/ref/csrf/#ajax
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

// ---

axiosApi.interceptors.request.use(request => {
  request.url = normalizeMultiSlashes(ensureTrailingSlash(request.url ?? '/'))
  return request
})

function ensureTrailingSlash(str: string): string {
  return str.replace(/([^/])$/, '$1/')
}

function normalizeMultiSlashes(str: string): string {
  // Keep double-slash at very start ("same protocol") and after colon (assuming explicit protocol)
  // Regexp should be simply (?<!:|^)\/+ – but lookbehind assertions don't work in Safari. In 2022, yep.
  return str.replace(/(^\/|[^:])(\/+)/g, '$1/')
}

// ---

axiosApi.interceptors.response.use(undefined, (error: AxiosError) => {
  const { response } = error
  const detail = (response?.data as Record<string, string>)?.detail
  const msg = detail ?? response?.statusText
  if (msg !== undefined) {
    error.message = msg
  }
  return Promise.reject(error)
})

// ---

export default axiosApi

// ---

type AxiosQueryConfig = RequiredProps<AxiosRequestConfig, 'url' | 'method'>

function isConfig(x: unknown): x is AxiosQueryConfig {
  return typeof x === 'object' && x !== null
}

/**
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
 */
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<string | AxiosQueryConfig> =>
  async arg => {
    let config: AxiosQueryConfig
    if (isConfig(arg)) {
      config = arg
    } else {
      config = { url: arg, method: 'get' }
    }
    config.url = `${baseUrl}/${config.url}`

    try {
      const result = await axiosApi(config)
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }
