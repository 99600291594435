import React from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'
import { useHistory } from 'react-router'

import { ErrorMessage } from 'components/ErrorMessage'
import { Suspense } from 'components/Suspense'

import { selectIsGuestUser } from 'features/User'

import { useAppDispatch, useAppSelector, useOnChange, useOnMount } from 'hooks'
import routes from 'routes'

import { reset } from '../../slice'

import { ExpertForm, ExpertFormProps } from './Form'

export function ExpertFormContainer(props: ExpertFormProps) {
  const dispatch = useAppDispatch()

  useOnMount(() => {
    return () => {
      dispatch(reset())
    }
  })

  useLogoutRedirect()

  return (
    <ErrorBoundary fallbackRender={renderPageError}>
      <Suspense fallback="Завантажуємо картку...">
        <ExpertForm {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

function renderPageError({ error }: { error: Error }) {
  if (error.message.toLowerCase().startsWith('not found')) {
    return (
      <ErrorMessage style={{ fontSize: '1em' }}>
        Такої картки не знайдено
      </ErrorMessage>
    )
  }

  return <ErrorMessage>{error.message}</ErrorMessage>
}

/**
 * This logic kinda violates general auth workflow.
 *
 * ExpertCard page should be available (for now) both for auth and guest users:
 * – moderators can open any card from registry
 * – expert opens his own card, having it's link in his profile
 * – guest will get link to his card (draft) after registration.
 *
 * Thus, we have no catches to manage user's access to this page (and auto-redirecting out of it)
 * basing on user's status/role.
 *
 * So instead we'll just assume that if logout happens while user is on this page –
 * that user obviously is not a guest with draft card (as he already has an account).
 * And so, being logged out, he shouldn't have access to this card.
 */
function useLogoutRedirect() {
  const history = useHistory()
  const isGuest = useAppSelector(selectIsGuestUser)
  useOnChange(isGuest, isGuest => {
    if (isGuest) {
      history.push(routes.home)
    }
  })
}
