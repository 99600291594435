import { Page } from 'components/layouts'

import { ExpertiseReviews } from 'features/ExpertiseReviews'

export function ExpertiseReviewsPage() {
  return (
    <Page showBackButton title="Відгуки по експертизі">
      <ExpertiseReviews />
    </Page>
  )
}
