import clsx from 'clsx'

import { DraftModeratorControls } from 'components/DraftModeratorControls'

import { ExpertiseFeedback } from 'features/ExpertiseReviews'
import { selectIsModeratorUser, selectIsSupervisorUser } from 'features/User'

import { useAppDispatch, useAppSelector } from 'hooks'

import { deleteFeedback, updateFeedback } from '../../../../thunks'
import { ReviewForm } from '../ReviewForm'

import styles from './styles.module.scss'

const modalTitle = 'Погодження змін даних у відгуку'

interface ReviewEntryProps {
  feedback: ExpertiseFeedback
}

export const ReviewEntry = ({ feedback }: ReviewEntryProps) => {
  const isModerator = useAppSelector(selectIsModeratorUser)
  const isSupervisor = useAppSelector(selectIsSupervisorUser)
  const dispatch = useAppDispatch()
  const { toberemoved, approved, id } = feedback
  const handleDeleteFeedback = () => dispatch(deleteFeedback({ id }))

  if (!isModerator) return null

  if (toberemoved) {
    return (
      <div className={clsx(styles.wrapper, styles.flex)}>
        <div className={styles.delete_text}>Видаляється існуючий відгук</div>
        <DraftModeratorControls
          onSubmit={isSupervisor ? handleDeleteFeedback : undefined}
          onReject={message =>
            dispatch(updateFeedback({ id, message, toberemoved: false }))
          }
          title={modalTitle}
          className={styles.btn_wrapper}
        />
      </div>
    )
  }

  if (approved) {
    return <div />
  }

  return (
    <div className={styles.wrapper}>
      <ReviewForm feedback={feedback} removable={false} />
      <DraftModeratorControls
        onSubmit={() => dispatch(updateFeedback({ id, approved: true }))}
        onReject={message => dispatch(deleteFeedback({ id, message }))}
        title={modalTitle}
        className={styles.btn_wrapper}
      />
    </div>
  )
}
