import React from 'react'

import { FormikContext, Form as FormikForm, useFormik } from 'formik'

import { Loader } from 'components/Loader'

import { FormErrorContext, useFormSubmitErrorInternal, useLatest } from './lib'
import { FormProps } from './types'

export function Form<Values>(props: FormProps<Values>) {
  const {
    children,
    formElementProps,
    resetSubmitErrorOnChange = true,
    className,
    style,
    loading,
    ...rest
  } = props

  const { submitError, setSubmitError, onSubmit, onReset } =
    useFormSubmitErrorInternal(rest)
  const formik = useFormik({
    ...rest,
    onSubmit,
    onReset,
  })

  const prevValues = useLatest(formik.values)
  if (
    resetSubmitErrorOnChange &&
    submitError !== undefined &&
    prevValues !== formik.values
  ) {
    setSubmitError(undefined)
  }

  return (
    <FormikContext.Provider value={formik}>
      <FormErrorContext.Provider value={submitError}>
        <FormikForm {...formElementProps} className={className} style={style}>
          {typeof children === 'function' ? children(formik) : children}
          {loading && <Loader />}
        </FormikForm>
      </FormErrorContext.Provider>
    </FormikContext.Provider>
  )
}
