import styles from './styles.module.scss'

export function getScoreColorClass(x: number | null) {
  if (x === null) {
    return styles.score_none
  }
  if (x <= 50) {
    return styles.score_low
  }
  if (x < 80) {
    return styles.score_mid
  }
  return styles.score_high
}
