import { AsyncState } from 'types'

export interface IUserResponse {
  id: number
  email: string
  first_name: string
  last_name: string
  groups: UserGroup[]
  card: {
    id: string | null // only Expert users have card.id
  }
}

export interface IUser extends Omit<IUserResponse, 'id'> {
  id?: number
}

export interface IExpertUser extends IUser {
  card: {
    id: string
  }
}

export interface IPrivilegedUser extends IUser {}

// ---

export interface IUserState extends AsyncState {
  data: IUser
}

export enum UserGroup {
  expert = 'Експерт',
  admin = 'Адміністратор',
  supervisor = 'Керівник',
  specialist = 'Фахівець',
  other = 'Інший працівник',
}
