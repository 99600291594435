import React, { ChangeEvent, useCallback } from 'react'
import { toast } from 'react-toastify'

import { Field, FieldProps } from 'formik'

import { Select, SelectOption } from 'components/Select'
import { FieldFile, FieldInput } from 'components/fields'

import { FILE_DEFAULT_NAME } from 'const'
import { ExpertFormDocumentPayload } from 'types'
import { normalizeDateInputValue } from 'utils'

import { useExpertForm } from '../../hooks'

import { FieldDocumentProps } from './types'

const DOC_TYPES = [
  'CV',
  'документ, який підтверджує статус особи і місце роботи/навчання',
  'науковий ступінь',
  'вчене звання',
  'допуск до державної таємниці',
  'обґрунтування спеціальності',
  'інший документ',
]

const DOC_TYPE_OPTIONS: SelectOption[] = DOC_TYPES.map(docName => ({
  value: docName,
  label: docName,
}))
const DOC_SIZE_ERROR = 'Занадто великий файл'
const MAX_FILE_SIZE = 50000000

export const FieldDocument = (props: FieldDocumentProps) => {
  const { name, label, disabled } = props
  const subName = useCallback(
    (x: keyof ExpertFormDocumentPayload) => `${name}.${x}`,
    [name]
  )

  const { setFieldValue, getFieldMeta } = useExpertForm()
  const { value: doc } = getFieldMeta<ExpertFormDocumentPayload>(name)

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = (e.currentTarget.files as FileList)[0]
      if (file === undefined) return
      if (file.size > MAX_FILE_SIZE) {
        toast.error(DOC_SIZE_ERROR)
        return
      }
      setFieldValue(subName('doc_file'), file)
      setFieldValue(subName('doc_filename'), file.name)
      setFieldValue(subName('doc_date'), normalizeDateInputValue(new Date()))
    },
    [subName, setFieldValue]
  )
  const handleDeleteFile = useCallback(() => {
    if (doc.doc_date) {
      setFieldValue(subName('doc_link'), '')
      setFieldValue(subName('doc_filename'), FILE_DEFAULT_NAME)
      setFieldValue(subName('doc_date'), '')
    }
  }, [subName, setFieldValue, doc])

  if (doc === undefined) return null
  return (
    <>
      <FieldFile
        onChange={handleFileChange}
        onDelete={handleDeleteFile}
        label={label}
        name={subName('doc_filename')}
        fileSrc={doc.doc_link}
        disabled={disabled}
      />

      {doc.doc_date && (
        <FieldInput
          label="Дата додавання"
          name={subName('doc_date')}
          type="date"
          disabled
        />
      )}

      <Field name={subName('doc_type')}>
        {({ field }: FieldProps) => (
          <Select
            options={DOC_TYPE_OPTIONS}
            label="Тип"
            name={subName('doc_type')}
            onChange={option =>
              setFieldValue(subName('doc_type'), option?.value ?? '')
            }
            value={field.value}
            disabled={disabled}
            isClearable
          />
        )}
      </Field>

      {/* TODO: get from user status */}
      <FieldInput
        label="Джерело"
        name={subName('doc_src')}
        value="Заявка"
        disabled
      />

      <FieldInput label="Примітка" name={subName('doc_desc')}>
        <Field as="textarea" name={subName('doc_desc')} disabled={disabled} />
      </FieldInput>
    </>
  )
}
