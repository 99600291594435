import { ExpertiseTable } from './components/ExpertiseTable'
import { Header } from './components/Header'

export const ExpertCabinet = () => {
  return (
    <main>
      <Header />
      <ExpertiseTable />
    </main>
  )
}
