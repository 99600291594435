import { MicrosoftLogin } from './components/MicrosoftLogin'

import styles from './styles.module.scss'

export const Login = () => {
  return (
    <div className={styles.login}>
      <h1 className={styles.header}>Сервіс Experts LMS</h1>
      <MicrosoftLogin />
      <div className={styles.footer}>
        <a
          href="https://support.microsoft.com/uk-ua/help/4026838/microsoft-account-change-your-password"
          target="_blank"
          rel="noreferrer"
        >
          Відновити пароль
        </a>
        <a href="https://wiki.naqa.gov.ua/" target="_blank" rel="noreferrer">
          Сторінка FAQ
        </a>
        <p className={styles.support}>
          <strong>Служба технічної підтримки: </strong>
          <a href="mailto:support@naqa.gov.ua" target="_blank" rel="noreferrer">
            support@naqa.gov.ua
          </a>
        </p>
      </div>
    </div>
  )
}
