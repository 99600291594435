import { CSSProperties, useEffect, useMemo } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Column } from 'react-table'

import Table from 'components/Table'

import { useAppDispatch, useAppSelector } from 'hooks'
import routes from 'routes'

import {
  selectExpertise,
  selectExpertiseLoading,
  selectFilters,
} from '../../selectors'
import * as thunks from '../../thunks'
import { IExpertise } from '../../types'

export const ExpertiseTable = () => {
  const loading = useAppSelector(selectExpertiseLoading)

  return (
    <Table
      columns={useColumns()}
      data={useData()}
      autoResetPage={false}
      loadingPlaceholder="Завантажуємо експертизи..."
      loading={loading}
    />
  )
}

function useData() {
  const dispatch = useAppDispatch()
  const { needModeration, search } = useAppSelector(selectFilters)
  const expertise = useAppSelector(selectExpertise)

  useEffect(() => {
    dispatch(thunks.getExpertise(needModeration))
  }, [needModeration, dispatch])
  // By design, we always download all the data. So this filter works on client side.
  return expertise.filter(({ IdEdboOp }) => IdEdboOp.includes(search))
}

const smallCellStyle: CSSProperties = { width: 90 }
const cellStyle: CSSProperties = { width: 250 }
const dateCellStyle: CSSProperties = { minWidth: 95 }

export function useColumns() {
  return useMemo<Column<IExpertise>[]>(
    () => [
      {
        accessor: 'ExpDateStart',
        Header: (
          <>
            Дата
            <br /> початку
          </>
        ),
        tx: {
          style: dateCellStyle,
        },
      },
      {
        accessor: 'ExpDateEnd',
        Header: (
          <>
            Дата
            <br /> заверш.
          </>
        ),
        tx: {
          style: dateCellStyle,
        },
      },
      {
        accessor: 'IdEdboOp',
        Header: 'ID ЄДЕБО ОП',
        tx: {
          style: smallCellStyle,
        },
        Cell: ({ value }) =>
          value ? (
            <Link to={generatePath(routes.expertise, { id: value })}>
              {value}
            </Link>
          ) : null,
      },
      {
        accessor: 'ExpDecreeNum',
        Header: 'Номер наказу',
        tx: {
          style: smallCellStyle,
        },
      },
      {
        accessor: 'ExpDecreeLink',
        Header: (
          <>
            Посилання на наказ
            <br /> в{' '}
            <a
              href="https://office.naqa.gov.ua/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://office.naqa.gov.ua/
            </a>
          </>
        ),
        tx: {
          style: {
            maxWidth: 280,
            minWidth: 90,
            wordBreak: 'break-all',
          },
        },
        Cell: ({ value }) => (
          <a
            href="https://office.naqa.gov.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        ),
      },
      {
        accessor: 'ExpZVO',
        Header: 'ЗВО де проводилася експертиза',
        tx: {
          style: cellStyle,
        },
      },
      {
        accessor: 'ExpEduProgramName',
        Header: 'Назва освітньої програми',
        tx: {
          style: cellStyle,
        },
      },
      {
        accessor: 'ExpEduSpec',
        Header: 'Спеціальність',
      },
      {
        accessor: 'ExpEduProgramLevel',
        Header: (
          <>
            Освітній
            <br /> рівень програми
          </>
        ),
      },
    ],
    []
  )
}
