import { createSlice } from '@reduxjs/toolkit'

import { addAsyncThunkReducers } from 'utils'

import * as thunks from './thunks'
import { ExpertCabinetSliceState } from './types'

const initialState: ExpertCabinetSliceState = {
  expertise: [],
  integral_score: null,
  loading: false,
  error: undefined,
}

export const ExpertCabinetSlice = createSlice({
  name: 'ExpertCabinet',
  initialState,

  reducers: {},

  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.getExpertise, 'expertise')
    addAsyncThunkReducers(builder, thunks.getIntegralScore, 'integral_score')
  },
})
