import { ReactChild, ReactFragment } from 'react'

import { useAppSelector } from 'hooks'

import { selectIsGuestUser } from '../selectors'

export const GuestOnly = ({
  children,
}: {
  children: ReactChild | ReactFragment
}) => {
  const isGuest = useAppSelector(selectIsGuestUser)
  return <>{isGuest ? children : null}</>
}
