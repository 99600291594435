import { FieldSelectDict, SystemDictionaryName } from 'features/Dicts'

import { FieldSelectProps } from './types'

export const FieldSelect = ({
  isCreatable = false,
  field,
  disabled,
}: FieldSelectProps) => {
  const { field_name, screen_field_name, guide_name } = field
  if (guide_name) {
    return (
      <FieldSelectDict
        name={field_name}
        dict={guide_name as SystemDictionaryName}
        label={screen_field_name}
        batch
        isCreatable={isCreatable}
        disabled={disabled}
        isClearable
      />
    )
  }

  return null
}
