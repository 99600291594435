import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { ColumnWithStrictAccessor } from 'react-table'

import Table from 'components/Table'
import { Bool, Nullable } from 'components/formatters'

import { useAppSelector } from 'hooks'
import routes from 'routes'

import { PersonsRegistryItem } from '../../types'

import { getScoreColorClass } from './lib'
import { RegistryTableProps } from './types'

import styles from './styles.module.scss'

const COMMENT_MAX_CHAR = 200

export function RegistryTable(props: RegistryTableProps) {
  const loading = useAppSelector(x => x.personsRegistry.loading)
  const { data } = props

  return (
    <Table
      className={styles.table}
      data={data}
      columns={useColumns()}
      loadingPlaceholder="Завантажуємо експертів..."
      loading={loading}
      autoResetPage={false}
    />
  )
}

type RegistryExportableColumn =
  ColumnWithStrictAccessor<PersonsRegistryItem> & {
    Header: string
  }

export function useColumns() {
  return useMemo<RegistryExportableColumn[]>(
    () => [
      {
        accessor: 'name',
        Header: 'ПIБ',
        Cell: ({ value, row }) => (
          <Link
            className={styles.user_name}
            to={generatePath(routes.form, { id: row.original.id })}
          >
            {value}
          </Link>
        ),
      },
      {
        accessor: 'score',
        Header: 'IO',
        Cell: ({ value }) => (
          <Nullable value={value}>{x => Math.round(x)}</Nullable>
        ),
        tx: {
          className: styles.cell_score,
        },
        td: ({ cell }) => ({
          className: getScoreColorClass(cell.value),
        }),
      },
      {
        accessor: 'region',
        Header: 'Область',
      },
      {
        accessor: 'phone',
        Header: 'Телефон',
      },
      {
        accessor: 'education',
        Header: 'ЗВО',
      },
      {
        accessor: 'speciality',
        Header: 'Спецiальнiсть (провiдна)',
      },
      {
        accessor: 'expert_status',
        Header: 'Статус експерта',
      },
      {
        accessor: 'is_recommended_as_lead',
        Header: 'РКЕГ',
        Cell: ({ value }) => (
          <Nullable value={value}>{x => <Bool>{x}</Bool>}</Nullable>
        ),
        tx: {
          className: styles.cell_abbrev,
        },
      },
      {
        accessor: 'expertise_count',
        Header: 'КПЕ',
        tx: {
          className: styles.cell_abbrev,
        },
      },
      {
        accessor: 'expertise_count_as_lead',
        Header: 'КЕК',
        tx: {
          className: styles.cell_abbrev,
        },
      },
      {
        accessor: 'is_in_expertise',
        Header: 'ЗЗЕ',
        Cell: ({ value }) => <Bool>{value}</Bool>,
        tx: {
          className: styles.cell_abbrev,
        },
      },
      {
        accessor: 'comment',
        Header: 'Поточний комментар',
        Cell: ({ value }) =>
          value.length > COMMENT_MAX_CHAR
            ? `${value.substring(0, COMMENT_MAX_CHAR)}...`
            : value,
      },
    ],
    []
  )
}
