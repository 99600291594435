import { DictionariesAPI } from 'features/Dicts'
import { selectExpertiseMembers } from 'features/ExpertiseReviews/selectors'

import { useAppSelector } from 'hooks'

import { ExpertiseMember } from './components/ExpertiseMember'

import styles from './styles.module.scss'

export const Reviews = () => {
  const { data: dicts } = DictionariesAPI.endpoints.getDicts.useQuery()
  const expertiseMembers = useAppSelector(selectExpertiseMembers)
  const titles = ['ПІБ', 'Область', 'НПП', 'Здобувач', 'ЗВО']

  if (expertiseMembers.length === 0) return null

  return (
    <>
      <div className={styles.flex}>
        {titles.map(item => (
          <div className={styles.title} key={item}>
            {item}
          </div>
        ))}
      </div>
      {dicts &&
        expertiseMembers.map(member => (
          <ExpertiseMember member={member} dicts={dicts} key={member.id} />
        ))}
    </>
  )
}
