import React, { useContext } from 'react'

import { useFormikContext } from 'formik'

import { RegistryFiltersFormValues } from '../../types'

import { IFilterFieldContext } from './types'

export function useFiltersForm() {
  return useFormikContext<RegistryFiltersFormValues>()
}

export const FilterFieldContext = React.createContext<
  IFilterFieldContext | undefined
>(undefined)

export function useFilterField(): IFilterFieldContext {
  const ctx = useContext(FilterFieldContext)
  if (ctx === undefined) {
    throw new Error(
      "'useFilterField()' is used outside of <FilterField> component"
    )
  }
  return ctx
}

export const initialValues: RegistryFiltersFormValues = {
  q: {
    enabled: true,
    value: '',
  },
  status: {
    enabled: false,
    value: '',
  },
  PersonSpeciality: [],
  IsPersonSpeciality: {
    enabled: false,
    value: '',
  },
  Specialization: {
    enabled: false,
    value: '',
  },
  is_NPP: {
    enabled: true,
    value: false,
  },
  is_Edu: {
    enabled: true,
    value: false,
  },
  ExpertStatusNPP: {
    enabled: false,
    value: '',
  },
  ExpertStatusStudent: {
    enabled: false,
    value: '',
  },
  ZVOName: {
    enabled: false,
    value: '',
  },
  HaveAdmStateSecret: {
    enabled: false,
    value: true,
  },
  EngLangLevel: {
    enabled: true,
    value: false,
  },
  IsAccredPhD: {
    enabled: false,
    value: true,
  },
  IsGERmember: {
    enabled: false,
    value: '',
  },
  FirstAllow: {
    enabled: true,
    value: false,
  },
  FirstAllowDate: {
    enabled: false,
    value: '',
  },
  IsOnlineCourseScore: {
    enabled: false,
    value: 10,
  },
  AddTrainingDate: {
    enabled: false,
    value: '',
  },
  IsTrainerRecommendHead: {
    enabled: false,
    value: true,
  },
  IsTrainerRecommendExpert: {
    enabled: false,
    value: true,
  },
  AddTrainingScore: {
    enabled: false,
    value: 10,
  },
  RecomInclToRegister: {
    enabled: false,
    value: true,
  },
  RecomInclToRegisterDate: {
    enabled: false,
    value: '',
  },
  ReadyCertJunBak: {
    enabled: true,
    value: false,
  },
  ReadyCertBak: {
    enabled: true,
    value: false,
  },
  ReadyCertMag: {
    enabled: true,
    value: false,
  },
  ReadyCertPhilDoc: {
    enabled: true,
    value: false,
  },
}
