import React, { createContext, useContext } from 'react'

import { FastField, FieldAttributes, Field as NormalField } from 'formik'

interface CustomFieldProps {
  fast?: boolean
}

export type BaseFieldProps<V = unknown> = FieldAttributes<V> & CustomFieldProps

/**
 * @see https://formik.org/docs/api/fastfield
 *
 * In absolute most of cases, forms are working with standalone fields.
 * Cross-fields dependencies are quite rate and specific, and should be specified explicitly.
 * Thus, there is no reason to not use optimized fast fields by default.
 */
function BaseFormikField<V>(props: BaseFieldProps<V>) {
  const ctx = useContext(FieldOptionsContext)
  const { fast = false, ...rest } = { ...ctx, ...props }
  const Component = fast ? FastField : NormalField
  return <Component {...rest} />
}

export const Field = BaseFormikField

// ---

type IFieldOptionsContext = CustomFieldProps
const FieldOptionsContext = createContext<CustomFieldProps>({})

export const FieldOptionsProvider: React.FC<IFieldOptionsContext> = props => {
  const { children, ...ctx } = props
  return (
    <FieldOptionsContext.Provider value={ctx}>
      {children}
    </FieldOptionsContext.Provider>
  )
}
