import { ReactChild } from 'react'
import { Link, generatePath } from 'react-router-dom'

import clsx from 'clsx'

import { Flex } from 'components/layouts'

import { IExpertise } from 'features/ExpertiseList'

import routes from 'routes'

import { DictionariesAPI, SystemDictionaryName } from '../../../Dicts'

import styles from './styles.module.scss'

interface ExpertiseDataProps {
  expertise: IExpertise
  showExpertiseLink?: boolean
}

export const ExpertiseData = (props: ExpertiseDataProps) => {
  const { expertise, showExpertiseLink = false } = props
  const { data: instDict } = DictionariesAPI.endpoints.getDict.useQuery(
    SystemDictionaryName.edu_institutions
  )
  const { data: specDict } = DictionariesAPI.endpoints.getDict.useQuery(
    SystemDictionaryName.specialities_edu
  )

  return (
    <>
      <Flex className={styles.flex} wrap={false}>
        <DataBlock title="ID ЄДЕБО ОП">
          <Choose>
            <When condition={showExpertiseLink}>
              <Link
                to={generatePath(routes.expertise, { id: expertise.IdEdboOp })}
              >
                {expertise.IdEdboOp}
              </Link>
            </When>
            <Otherwise>{expertise.IdEdboOp}</Otherwise>
          </Choose>
        </DataBlock>
        <DataBlock title="Дата початку">{expertise.ExpDateStart}</DataBlock>
        <DataBlock title="Дата завершення">{expertise.ExpDateEnd}</DataBlock>
        <DataBlock title={instDict?.[expertise.ExpZVO]} />
      </Flex>
      <Flex gap={30}>
        <DataBlock title="Назва освітньої програми">
          {expertise.ExpEduProgramName}
        </DataBlock>
        <DataBlock title="Спеціальність">
          {specDict?.[expertise.ExpEduSpec]}
        </DataBlock>
      </Flex>
    </>
  )
}

interface DataBlockProps {
  title?: string
  children?: ReactChild
  className?: string
}
function DataBlock({ title, children, className }: DataBlockProps) {
  return (
    <div className={clsx(styles.data, className)}>
      <div className={styles.title}>{title}</div>
      {children && <div className={styles.value}>{children}</div>}
    </div>
  )
}
