import { useCallback, useEffect, useRef } from 'react'

import { useSwitch } from './useSwitch'

// ---

type Callback<T> = T extends (...args: infer Args) => unknown
  ? (...args: Args) => void
  : () => void

interface IOptions {
  onOpen?: Func
  onCancel?: Func
  onConfirm?: Func
  onConfirmSuccess?: () => void
  onConfirmError?: (e: Error) => void
  onConfirmFinally?: () => void
  onClose?: () => void
}

interface IConfirm<T extends IOptions> {
  isOpen: boolean
  open: Callback<T['onOpen']>
  confirm: Callback<T['onConfirm']>
  cancel: Callback<T['onCancel']>
}

// ---

export function useConfirm<T extends IOptions>(
  options: T = {} as T
): IConfirm<T> {
  const [checked, toggle] = useSwitch(false)
  const { on, off } = useSafeMethods(toggle)

  const {
    onOpen,
    onCancel,
    onConfirm,
    onConfirmError,
    onConfirmSuccess,
    onConfirmFinally,
    onClose,
  } = options

  return {
    isOpen: checked,

    open: useCallback(
      (...args: unknown[]) => {
        onOpen?.(...args)
        on()
      },
      [onOpen, on]
    ) as Callback<T['onOpen']>,

    cancel: useCallback(
      (...args: unknown[]) => {
        onCancel?.(...args)
        onClose?.()
        off()
      },
      [onCancel, onClose, off]
    ) as Callback<T['onCancel']>,

    confirm: useCallback(
      async (...args: unknown[]) => {
        try {
          await onConfirm?.(...args)
          onConfirmSuccess?.()
          onClose?.()
          off()
        } catch (e) {
          if (e instanceof Error || (e instanceof Object && 'message' in e)) {
            onConfirmError?.(e)
          } else {
            onConfirmError?.(new Error(String(e)))
          }
        } finally {
          onConfirmFinally?.()
        }
      },
      [
        onClose,
        onConfirm,
        onConfirmSuccess,
        onConfirmError,
        onConfirmFinally,
        off,
      ]
    ) as Callback<T['onConfirm']>,
  }
}

// ---

function useIsMountedRef() {
  const ref = useRef(false)
  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])
  return ref
}

function useSafeMethods({ on, off }: { on: Func; off: Func }) {
  const refIsMounted = useIsMountedRef()
  const safeOn = useCallback(() => {
    if (refIsMounted.current) {
      on()
    }
  }, [refIsMounted, on])
  const safeOff = useCallback(() => {
    if (refIsMounted.current) {
      off()
    }
  }, [refIsMounted, off])
  return { on: safeOn, off: safeOff }
}
