import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  AsyncState,
  ExpertFormFieldSchema,
  ExpertFormResponse,
  ExpertFormStatus,
  ExpertFormValues,
  ExpertFormValuesState,
} from 'types'
import { addAsyncThunkReducers } from 'utils'

import { normalizeFormValues } from './lib'
import * as thunks from './thunks'

export interface ExpertFormSliceState extends AsyncState {
  fieldSchemas: ExpertFormFieldSchema[]
  initialValues: ExpertFormValuesState
  activeTab?: string
  formId?: ExpertFormResponse['id']
  userId?: ExpertFormResponse['user_id']
  formStatus: ExpertFormStatus
}

const initialDraft = {
  tobeadded: {},
  toberemoved: {},
  tobemodified: {},
}

const initialState: ExpertFormSliceState = {
  formId: undefined,
  userId: undefined,
  fieldSchemas: [],
  initialValues: {
    current: {},
    changed: initialDraft,
  },
  activeTab: undefined,
  formStatus: ExpertFormStatus.new,
  loading: false,
  error: undefined,
}

const expertFormSlice = createSlice({
  name: 'expertForm',
  initialState,

  reducers: {
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload
    },
    setFieldValues(state, action: PayloadAction<ExpertFormValues>) {
      state.initialValues = { ...state.initialValues, ...action.payload }
    },
    setFormStatus(state, action: PayloadAction<ExpertFormStatus>) {
      state.formStatus = action.payload
    },
    reset() {
      return initialState
    },
    resetError(state) {
      state.error = undefined
    },
  },

  extraReducers: builder => {
    builder
      .addCase(thunks.getFormFields.fulfilled, (state, action) => {
        const {
          schemas,
          values,
          status,
          id,
          user_id,
          draft = initialDraft,
        } = action.payload
        state.formId = id
        state.userId = user_id
        state.fieldSchemas = schemas
        state.initialValues.current = normalizeFormValues(schemas, values)
        state.initialValues.changed = draft
        if (status !== undefined) {
          state.formStatus = status
        }
      })
      .addCase(thunks.deleteForm.fulfilled, state => {
        state.initialValues = {
          current: normalizeFormValues(state.fieldSchemas, {}),
          changed: initialDraft,
        }
      })

    addAsyncThunkReducers(builder, thunks.submitForm, assignFieldsState)

    addAsyncThunkReducers(builder, thunks.applyChange, assignFieldsState)

    addAsyncThunkReducers(builder, thunks.updateCardStatus, 'formStatus')
  },
})

function assignFieldsState(
  state: ExpertFormSliceState,
  {
    values,
    draft,
  }: {
    values: ExpertFormValuesState['current']
    draft: ExpertFormValuesState['changed']
  }
) {
  state.initialValues.current = values
  state.initialValues.changed = draft
}

export const {
  setActiveTab,
  setFieldValues,
  setFormStatus,
  reset,
  resetError,
} = expertFormSlice.actions

export default expertFormSlice.reducer
