import { stringify as csvStringify } from 'csv-stringify/dist/esm/sync'

import { resolveButtonClasses } from 'components'

import { NULL_PLACEHOLDER } from 'const'
import { useAppSelector } from 'hooks'
import { formatBool } from 'utils'

import { useColumns } from '../RegistryTable'

function formatCSVCell(data: unknown) {
  const value = data ?? NULL_PLACEHOLDER
  return typeof value === 'boolean' ? formatBool(value) : value
}

export const ExportButton = () => {
  const rows = useAppSelector(x => x.personsRegistry.items)
  const columns = useColumns()

  const csv = csvStringify([
    columns.map(x => x.Header),
    ...rows.map(row =>
      columns.map(column => row[column.accessor]).map(formatCSVCell)
    ),
  ])

  return (
    <a
      href={`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`}
      className={resolveButtonClasses('secondary')}
      download
    >
      Експортувати в CSV
    </a>
  )
}
