import { useMemo } from 'react'

import clsx from 'clsx'

import { Button } from 'components'
import { FieldSetLayout } from 'components/FieldSetLayout'

import {
  SystemDictionaries,
  SystemDictionaryName as dictName,
} from 'features/Dicts'
import {
  ExpertiseMember as IMember,
  addEmptyFeedback,
} from 'features/ExpertiseReviews'
import { selectExpertiseFeedbacks } from 'features/ExpertiseReviews/selectors'
import { selectIsExpertUser, selectUser } from 'features/User'

import { useAppDispatch, useAppSelector } from 'hooks'
import { ExpertFormValues } from 'types'

import { ReviewAdded } from '../ReviewAdded'
import { ReviewEntry } from '../ReviewEntry'

import styles from './styles.module.scss'

interface ExpertiseMemberProps {
  member: IMember
  dicts: SystemDictionaries
}

export const ExpertiseMember = (props: ExpertiseMemberProps) => {
  const { member, dicts } = props
  const fields = member.fields
  const feedbacks = useFeedbacksForExpert(member.user_id)

  const region = dicts[dictName.ua_regions][fields.Region[0].Region as string]
  const isStudent = +fields.StudentScientist[0].StudentScientist === 2
  const workPlace = useMemo(() => {
    const fieldName = 'Місце_роботи_(поточне_і_попередні)'
    const institutions = dicts[dictName.edu_institutions]
    const currentWork = fields[fieldName].find(x => x.IsWorkPlaceCurrent)
    return institutions[
      (currentWork?.WorkZVOName ?? fields[fieldName][0].WorkZVOName) as string
    ]
  }, [fields, dicts])

  return (
    <div className={styles.review} key={member.id}>
      <div className={clsx(styles.expert, styles.flex)}>
        <div>{getFullName(fields)}</div>
        <div>{region}</div>
        <div>{isStudent ? 'Ні' : 'Так'}</div>
        <div>{isStudent ? 'Так' : 'Ні'}</div>
        <div>{workPlace}</div>
        <AddFeedbackButton expert_id={member.user_id} />
      </div>
      {feedbacks.map(feedback => (
        <FieldSetLayout
          key={feedback.id}
          left={<ReviewAdded feedback={feedback} toExpert={member.user_id} />}
          right={<ReviewEntry feedback={feedback} />}
        />
      ))}
    </div>
  )
}

function getFullName(fields: ExpertFormValues): string {
  const name = fields['ПІБ'][0]
  return `${name.LName} ${name.FName} ${name.MName}`
}

function AddFeedbackButton(props: { expert_id: IMember['user_id'] }) {
  const { expert_id } = props

  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)

  const canAddFeedback = useCanAddFeedbackForExpert(expert_id)

  return (
    <Button
      className={styles.btn}
      onClick={() =>
        dispatch(addEmptyFeedback({ toExpert: expert_id, author: user }))
      }
      disabled={!canAddFeedback}
    >
      Додати
    </Button>
  )
}

function useFeedbacksForExpert(expert_id: IMember['user_id']) {
  return useAppSelector(state =>
    selectExpertiseFeedbacks(state).filter(x => x.to_expert === expert_id)
  )
}

function useCanAddFeedbackForExpert(expert_id: IMember['user_id']) {
  const user = useAppSelector(selectUser)
  const isExpert = useAppSelector(selectIsExpertUser)
  const feedbacks = useFeedbacksForExpert(expert_id)
  return !(
    isExpert &&
    (feedbacks.some(x => x.feedback_author === user.id) ||
      expert_id === user.id)
  )
}
