import { useDispatch } from 'react-redux'

import clsx from 'clsx'

import { AuthOnly, logout } from 'features/User'

import styles from './styles.module.scss'

export function AuthMenu() {
  const dispatch = useDispatch()
  return (
    <ul className={clsx(styles.auth_menu, styles.nav)}>
      <AuthOnly>
        {user => (
          <li>
            <span className={styles.username}>
              {user.first_name} {user.last_name}
            </span>
          </li>
        )}
      </AuthOnly>

      <li>
        <AuthOnly>
          <span
            className={clsx(styles.nav_link, styles.btn_logout)}
            onClick={() => {
              dispatch(logout())
            }}
          >
            Вийти
          </span>
        </AuthOnly>
      </li>
    </ul>
  )
}
