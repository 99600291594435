import { Fragment, useMemo, useState } from 'react'

import { groupBy, isEmpty } from 'lodash'

import { IExpertise } from 'features/ExpertiseList'
import {
  ExpertiseData,
  ExpertiseFeedbackResponse,
  ExpertiseReviewsAPI,
  ReviewForm,
} from 'features/ExpertiseReviews'

import { getExpertise } from 'api/Expertise'
import { useOnMount } from 'hooks'

import styles from './styles.module.scss'

type FeedbackType = Record<string, ExpertiseFeedbackResponse[]>

export const Feedback = ({ userId }: { userId: number }) => {
  const [feedback, setFeedback] = useState<FeedbackType>({})
  const [expertiseList, setExpertiseList] = useState<IExpertise[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useOnMount(() => {
    setIsLoading(true)
    async function fetchData() {
      const { data: feedback } = await ExpertiseReviewsAPI.getFeedback({
        expert_id: String(userId),
      })
      setFeedback(groupBy(feedback, x => x.idEdboOp))
      const expertiseIdList = Array.from(new Set(feedback.map(x => x.idEdboOp)))
      const expertise = await Promise.all(
        expertiseIdList.map(getExpertise)
      ).then(xs => xs.map(x => x.data))
      setExpertiseList(expertise)
      setIsLoading(false)
    }
    fetchData()
  })

  const sortedExpertiseList = useMemo(
    () =>
      [...expertiseList].sort((prev, next) => {
        if (!prev.ExpDateEnd) return -1
        if (!next.ExpDateEnd) return 1
        return Date.parse(next.ExpDateEnd) - Date.parse(prev.ExpDateEnd)
      }),
    [expertiseList]
  )

  if (isEmpty(feedback))
    return (
      <h3 className={styles.header}>
        {isLoading ? 'Завантажуємо відгуки...' : 'Відгуків немає'}
      </h3>
    )

  return (
    <>
      {sortedExpertiseList.map(expertise => {
        return (
          <div className={styles.expertise} key={expertise.IdEdboOp}>
            <ExpertiseData expertise={expertise} showExpertiseLink />
            {feedback[expertise.IdEdboOp].map(feedback => (
              <Fragment key={feedback.id}>
                <ReviewForm feedback={feedback} removable={false} />
                <hr />
              </Fragment>
            ))}
          </div>
        )
      })}
    </>
  )
}
