export enum SystemDictionaryName {
  person_type = 'Здобувач або НПП', // НПП = "Науково-педагогічний працівник"
  ua_regions = 'Області України',
  science_degree = 'Тип наукового ступеню',
  science_nomenclature = 'Номенклатура наукових ступенів',
  specialities_npp = 'Спеціальності НПП',
  specialities_edu = 'Спеціальності освітні',
  specialization = 'Спеціалізації',
  adm_secret_access = 'Допуск до державної таємниці',
  edu_institutions = 'ЗВО', // "Заклад Вищої Освіти"
  edu_degree = 'Рівень вищої освіти',
  edu_status = 'Статус особи, що навчається',
  edu_title = 'Тип вченого звання',
  ua_lang_level = 'Володіння українською мовою',
  en_lang_level = 'Володіння англійською мовою',
  expert_groups = 'Назва ГЕР', // "Галузева Експертна Група"
  info_sources = 'Джерела інформації',
  review_sources = 'Джерела відгуків',
  trainings = 'Назви тренінгів',
}

export interface SystemDictionary {
  name: SystemDictionaryName
  pairs: Record<string, string>
}

export type SystemDictionaries = Record<
  SystemDictionary['name'],
  SystemDictionary['pairs']
>
