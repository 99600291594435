import { Button } from 'components'

import { selectIsGuestUser } from 'features/User'

import { useAppSelector } from 'hooks'
import { ExpertFormStatus } from 'types'

import { useIsFormDisabled } from '../../hooks'
import { selectExpertFormStatus } from '../../selectors'

import { CancelButton } from './CancelButton'
import { ExportButton } from './ExportButton'
import { SendToReviewButton } from './SendToReviewButton'

import styles from './styles.module.scss'

export function Footer(props: { onSendToReview: () => void }) {
  const { onSendToReview } = props
  const formStatus = useAppSelector(selectExpertFormStatus)
  const isGuest = useAppSelector(selectIsGuestUser)
  const isDisabled = useIsFormDisabled()

  const isNewCard = formStatus === ExpertFormStatus.new

  return (
    <div className={styles.footer}>
      {/* TODO: can moderator delete a card? can expert request delete his own card? */}
      <If condition={isNewCard}>
        <CancelButton />
      </If>

      {/* Only a guest can send form to review (like "add me to system please").
         All registered users just save form normally.
         Guest also can "just save" form – which in this case means "save draft before sending to review". */}
      <If condition={isNewCard && isGuest}>
        <SendToReviewButton onConfirm={onSendToReview} />
      </If>

      <If condition={!isGuest}>
        <ExportButton />
      </If>

      <Button kind="secondary" type="submit" disabled={isDisabled}>
        Зберегти
      </Button>
    </div>
  )
}
