import clsx from 'clsx'

import { Button as BaseButton, ButtonProps, useConfirmModal } from 'components'
import { Flex } from 'components/layouts'

import { useAppDispatch, useAppSelector } from 'hooks'
import { ExpertFormStatus } from 'types'

import { selectExpertForm } from '../../selectors'
import * as thunks from '../../thunks'

import styles from './btn-status.module.scss'

/**
 * @see https://docs.google.com/document/d/1Cd2rORT_i2pdX-WY-VQRzavi-Q5b0Bwr7W-pOGuhWo8/edit?pli=1#bookmark=id.20boaeo5jn2y
 */
export function CardStatusButtons() {
  const { formStatus: status } = useAppSelector(selectExpertForm)

  return (
    <Flex>
      <Choose>
        <When condition={status === ExpertFormStatus.sent}>
          <Button status={ExpertFormStatus.new}>
            Відправити на доопрацювання
          </Button>

          <ButtonApprove status={ExpertFormStatus.candidate}>
            Перевести до кандидатів
          </ButtonApprove>

          <ButtonDecline status={ExpertFormStatus.rejected}>
            Відхилити заявку
          </ButtonDecline>
        </When>

        <When condition={status === ExpertFormStatus.candidate}>
          <ButtonApprove status={ExpertFormStatus.expert}>
            Перевести в експерти
          </ButtonApprove>

          <ButtonDecline status={ExpertFormStatus.rejected}>
            Відхилити заявку
          </ButtonDecline>
        </When>

        <When condition={status === ExpertFormStatus.expert}>
          <ButtonDecline status={ExpertFormStatus.removed}>
            Вилучити з реєстру експертів
          </ButtonDecline>
        </When>

        <When condition={status === ExpertFormStatus.rejected}>
          <Button status={ExpertFormStatus.new}>Редагувати анкету</Button>
        </When>

        <When condition={status === ExpertFormStatus.removed}>
          <Button status={ExpertFormStatus.new}>Редагувати анкету</Button>
        </When>
      </Choose>
    </Flex>
  )
}

// ---

type CardStatusButtonProps = Omit<ButtonProps, 'kind' | 'onClick'> & {
  status: ExpertFormStatus
}

function Button(props: CardStatusButtonProps) {
  const { status, className, children } = props
  const dispatch = useAppDispatch()

  const dialog = useConfirmModal({
    title: 'Будь ласка, підтвердіть обрану дію',
    content: <div className={styles.confirm_action}>{children}</div>,
    onConfirm() {
      dispatch(thunks.updateCardStatus(status))
    },
  })

  return (
    <>
      <BaseButton
        kind="primary"
        {...props}
        className={clsx(styles.base, className)}
        onClick={dialog.open}
      />
      {dialog.$element}
    </>
  )
}

function ButtonDecline(props: CardStatusButtonProps) {
  return <Button {...props} className={styles.decline} />
}

function ButtonApprove(props: CardStatusButtonProps) {
  return <Button {...props} className={styles.approve} />
}
