import { createBrowserHistory } from 'history'

const routes = {
  home: '/',
  form: '/form/:id',
  documents: '/documents',
  registration: '/registration',
  login: '/login',
  registry: '/registry',
  expertCab: '/cabinet',
  expertiseList: '/expertise',
  expertise: '/expertise/:id',
} as const

export default routes

export const history = createBrowserHistory({
  basename: '/',
})
