import { useParams } from 'react-router-dom'

import { Page } from 'components/layouts'

import { ExpertForm, ExpertFormProps } from 'features/ExpertForm'

export function ExpertFormPage() {
  const { id } = useParams<ExpertFormProps>()

  return (
    <Page title="Картка особи" showBackButton>
      <ExpertForm id={id} />
    </Page>
  )
}
