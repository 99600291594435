import { ReactChild } from 'react'

import { NULL_PLACEHOLDER } from 'const'
import { formatBool } from 'utils'

interface NullableProps<T> {
  value: T
  placeholder?: string
  children?: (x: NonNullable<T>) => ReactChild
}

export function Nullable<T>(props: NullableProps<T>) {
  const { value, children, placeholder = NULL_PLACEHOLDER } = props

  if (value === null || value === undefined) {
    return <>{placeholder}</>
  }

  if (children === undefined) {
    return <>{value}</>
  }

  return <>{children(value as NonNullable<T>)}</>
}

export function Bool(props: { children: boolean }) {
  const { children: value } = props
  return <>{formatBool(value)}</>
}
