/**
 * This script is borrowed from somewhere in internet and slightly adopted for our needs.
 */

const origin = window.location.protocol + '//' + window.location.host

const loginWindowParams = Object.entries({
  height: 560,
  width: 790,
  left: 550,
  top: 200,
  menubar: 'no',
  location: 'no',
  resizable: 'no',
  scrollbars: 'yes',
  status: 'no',
  toolbar: 'no',
})
  .map(([k, v]) => `${k}=${v}`)
  .join(',')

let loginWindow: Window | null = null

export function runMicrosoftLogin(
  authUrl: string | Promise<string>
): Promise<void> {
  if (loginWindow !== null) {
    loginWindow.close()
    loginWindow = null
  }

  return new Promise<void>(async (resolve, reject) => {
    window.addEventListener('message', receiveMessage)

    // Make sure that you DO NOT use any `await` before opening a new window,
    // so that window opens *synchronously* on user action,
    // and it won't be blocked by browser's anti-malware policies.
    // @see https://utility.quantumobile.co/task?id=18319
    loginWindow = window.open('', 'microsoft-login', loginWindowParams)

    if (loginWindow === null) {
      console.error('Failed to open login window')
      return
    }

    // when window is opened, then it's ok to wait for async operations and update window content:
    loginWindow.location.assign(await authUrl)

    // ---

    function receiveMessage(event: MessageEvent) {
      if (event.origin === undefined) {
        // @ts-expect-error not sure what exactly it does, but it works
        // eslint-disable-next-line no-param-reassign
        event = event.originalEvent
      }

      const isMSLoginMessage =
        // verify the message is from us
        event.origin === origin &&
        // and has data we need
        event.data.microsoft_auth

      if (!isMSLoginMessage) return

      // cleanup
      window.removeEventListener('message', receiveMessage)
      loginWindow?.close()
      loginWindow = null

      // resolve
      const data = event.data.microsoft_auth
      if (data.error) {
        reject(data.error_description)
      } else {
        resolve()
      }
    }
  })
}
