import React from 'react'

import clsx from 'clsx'

import { FieldSetLayout } from 'components/FieldSetLayout'

import { selectIsModeratorUser } from 'features/User'

import { useAppSelector } from 'hooks'

import { ExpertCardDraftControls } from '../../../ExpertCardDraftControls'
import {
  getGroupParams,
  useChangedDraftEntry,
  useRemovedDraftEntry,
} from '../../lib'
import { FieldGroup } from '../../types'
import { FieldSet } from '../FieldSet'

import styles from './styles.module.scss'

interface EntryProps {
  group: FieldGroup
  id: string
}

export function AddedEntry({ group, id }: EntryProps) {
  return (
    <FieldSetLayout
      left={<AddedEntryPlaceholder />}
      right={
        <FieldSet
          id={id}
          group={group}
          dataSource="changed.tobeadded"
          draft
          readOnly
        />
      }
    />
  )
}

export function RemovedEntry({ group, id }: EntryProps) {
  return (
    <FieldSetLayout
      left={
        <FieldSet
          id={id}
          group={group}
          dataSource="changed.toberemoved"
          readOnly
        />
      }
      right={
        <RemovedEntryPlaceholder>
          <ExpertCardDraftControls id={id} />
        </RemovedEntryPlaceholder>
      }
    />
  )
}

export function ChangedEntry({ group, id }: EntryProps) {
  return (
    <FieldSetLayout
      left={<FieldSet id={id} group={group} dataSource="current" />}
      right={
        <FieldSet
          id={id}
          group={group}
          dataSource="changed.tobemodified"
          draft
          readOnly
        />
      }
    />
  )
}

export function NormalEntry({
  group,
  id,
  columns = 1,
}: EntryProps & { columns?: 1 | 2 }) {
  return (
    <FieldSetLayout
      left={<FieldSet id={id} group={group} dataSource="current" />}
      right={columns === 2 ? <div /> : null}
    />
  )
}

export function GroupEntry({ group, id }: EntryProps) {
  const { group_name } = getGroupParams(group)
  const isRemoved = useRemovedDraftEntry(group_name, id) !== undefined
  const isChanged = useChangedDraftEntry(group_name, id) !== undefined

  const isModerator = useAppSelector(selectIsModeratorUser)
  if (!isModerator) {
    return <NormalEntry group={group} id={id} />
  }

  if (isRemoved) {
    return <RemovedEntry group={group} id={id} />
  }

  if (isChanged) {
    return <ChangedEntry group={group} id={id} />
  }

  // For moderator force 2-columns layout even for unchanged entries –
  // to keep it visually in sync with changed ones.
  return <NormalEntry group={group} id={id} columns={2} />
}

// ---

const AddedEntryPlaceholder: React.FC = ({ children }) => {
  return (
    <div className={styles.placeholder}>
      <span>Додаються нові дані</span>
      {children && <div className={styles.controls}>{children}</div>}
    </div>
  )
}

const RemovedEntryPlaceholder: React.FC = ({ children }) => {
  return (
    <div className={clsx(styles.placeholder, styles.placeholder_removed)}>
      <span>Видаляються існуючі дані (зліва)</span>
      {children && <div className={styles.controls}>{children}</div>}
    </div>
  )
}
