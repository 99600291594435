import { useMemo } from 'react'

import { useChanged } from 'hooks'

import { UserGroup } from '../types'

import { useUser } from './hooks'

type RolesAccessMode = 'allow' | 'deny'
type RolesMatchMode = 'all' | 'any'

type RoleOrList = UserGroup | UserGroup[]
type RoleOptions = {
  mode?: RolesAccessMode
  match?: RolesMatchMode
}

export interface IRoleAccessOptions extends RoleOptions {
  roles: UserGroup[]
}

export function useRoleAccess(options: IRoleAccessOptions): boolean

export function useRoleAccess(role: RoleOrList, options?: RoleOptions): boolean

export function useRoleAccess(...args: unknown[]): boolean {
  const options = resolveArgs(...args)

  const { roles, mode = 'allow', match = 'any' } = options

  const groups = useChanged(useUser().groups)
  const changedRoles = useChanged(Array.isArray(roles) ? roles : [roles])

  const isRolesMatch = useMemo(() => {
    const matches = (x: UserGroup) => groups.includes(x)
    return match === 'all'
      ? changedRoles.every(matches)
      : changedRoles.some(matches)
  }, [changedRoles, groups, match])

  const accessAllowed =
    (mode === 'allow' && isRolesMatch) || (mode === 'deny' && !isRolesMatch)

  return accessAllowed
}

// ---

const resolveRolesList = (x: RoleOrList): UserGroup[] =>
  ([] as UserGroup[]).concat(x)

function resolveArgs(...args: unknown[]): IRoleAccessOptions {
  if (args.length === 2) {
    const [roles, options] = args as [RoleOrList, RoleOptions]
    return {
      roles: resolveRolesList(roles),
      mode: options.mode,
      match: options.match,
    }
  }

  const [arg] = args as [RoleOrList | IRoleAccessOptions]

  if (Array.isArray(arg) || typeof arg === 'string') {
    return {
      roles: resolveRolesList(arg),
    }
  }

  return arg
}
