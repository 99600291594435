import { Button } from 'components'

import { login } from 'features/User'

import { useAppDispatch } from 'hooks'

import styles from './styles.module.scss'

export const MicrosoftLogin = () => {
  const dispatch = useAppDispatch()
  return (
    <Button
      className={styles.btn}
      onClick={() => {
        dispatch(login('microsoft'))
      }}
    >
      Вхід через офіс 365
    </Button>
  )
}
