import { IExpertise } from 'features/ExpertiseList'
import { ExpertiseResponse } from 'features/ExpertiseReviews'

import axiosInstance from './api'

const BASE_URL = '/cards/expertise'

export const getExpertiseList = (query?: boolean) =>
  axiosInstance.get<IExpertise[]>(BASE_URL, {
    params: { moderation: query },
  })

export const getExpertise = (id: string) =>
  axiosInstance.get<ExpertiseResponse>(`${BASE_URL}/${id}`)
