import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router'

import { unwrapResult } from '@reduxjs/toolkit'

import {
  getFormFields,
  selectExpertForm,
  submitForm,
} from 'features/ExpertForm'

import { useAppDispatch, useAppSelector, useOnMount } from 'hooks'
import routes from 'routes'
import { ExpertFormStatus } from 'types'

import { FormValues, getFormPayload } from './lib'

export function useSubmitHandler() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { initialValues } = useAppSelector(selectExpertForm)

  useOnMount(() => {
    dispatch(getFormFields())
  })

  return useCallback(
    async (values: FormValues) => {
      const payload = getFormPayload(values, initialValues.current)
      const resp = unwrapResult(
        await dispatch(
          submitForm({ values: payload, status: ExpertFormStatus.new })
        )
      )
      history.push(generatePath(routes.form, { id: resp.id }))
    },
    [dispatch, history, initialValues]
  )
}
