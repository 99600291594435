import { unzip, zipObject } from 'lodash'

/* eslint-disable @typescript-eslint/no-explicit-any */
type Arr = any[]
type ArrCb<T extends Arr> = Func1<T[0]>
type ArrMap<T extends Arr, F extends ArrCb<T>> = Array<ReturnTypeMaybeAsync<F>>

type Rec = Record<any, any>
type RecCb<T> = Func1<Values<T>>
type RecMap<T extends Rec, F extends RecCb<T>> = Record<
  keyof T,
  ReturnTypeMaybeAsync<F>
>
/* eslint-enable */

async function mapValuesAsync<T extends Rec, F extends RecCb<T>>(
  obj: T,
  fn: F
): Promise<RecMap<T, F>> {
  type K = keyof T
  type V = Values<T>
  const [keys = [], values = []] = unzip(Object.entries(obj)) as [K[], V[]]
  const newValues = await Promise.all(values.map(fn))
  return zipObject(keys, newValues) as RecMap<T, F>
}

async function mapAsync<T extends Arr, F extends ArrCb<T>>(
  x: T,
  fn: F
): Promise<ArrMap<T, F>>

async function mapAsync<T extends Rec, F extends RecCb<T>>(
  x: T,
  fn: F
): Promise<RecMap<T, F>>

async function mapAsync(x: Arr | Rec, fn: Func) {
  return Array.isArray(x) ? Promise.all(x.map(fn)) : mapValuesAsync(x, fn)
}

export const xmap = mapAsync
