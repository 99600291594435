import { Field } from 'formik'

import { Button, Form } from 'components'
import { FieldInput } from 'components/fields'
import { Flex } from 'components/layouts'

import { FieldSelectDict, SystemDictionaryName } from 'features/Dicts'
import { selectIsModeratorUser, selectUser } from 'features/User'

import { useAppDispatch, useAppSelector } from 'hooks'

import { selectExpertise } from '../../../../selectors'
import { addFeedback } from '../../../../thunks'
import { DeleteButton } from '../DeleteButton'

import { FormSchema, InitialValues, ReviewFormProps } from './lib'

import styles from './form.module.scss'

export const ReviewForm = ({
  expertId,
  feedback,
  removable = true,
}: ReviewFormProps) => {
  const user = useAppSelector(selectUser)
  const expertise = useAppSelector(selectExpertise)
  const isModerator = useAppSelector(selectIsModeratorUser)
  const dispatch = useAppDispatch()

  const initialValues = {
    scores: feedback.feedback_score ?? '',
    source: (feedback.feedback_source ?? '').toString(),
    message: feedback.feedback_text ?? '',
    email: feedback.feedback_author_email ?? '',
  }

  const isDisabled = !!feedback.feedback_text

  const handleSubmit = (values: InitialValues) => {
    const payload = {
      to_expert: expertId,
      feedback_source: +values.source,
      feedback_author: user.id,
      feedback_author_email: user.email,
      feedback_score: +values.scores,
      feedback_text: values.message,
      idEdboOp: expertise?.IdEdboOp,
      approved: isModerator,
    }
    dispatch(addFeedback({ removedId: feedback.id, payload }))
  }

  return (
    <Form<InitialValues>
      initialValues={initialValues}
      validationSchema={FormSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <fieldset disabled={isDisabled}>
        <Flex className={styles.flex_container}>
          <FieldInput
            label="Числова оцінка"
            min="0"
            max="100"
            type="number"
            name="scores"
          />
          <FieldInput label="Автор видгуку" name="email" disabled />
          <FieldSelectDict
            label="Джерело відгуку"
            name="source"
            dict={SystemDictionaryName.review_sources}
            batch
            disabled={isDisabled}
          />
        </Flex>
        <FieldInput label="Текстовий відгук" name="message">
          <Field className={styles.textarea} as="textarea" name="message" />
        </FieldInput>
      </fieldset>
      <div className={styles.btn_wrapper}>
        {removable && <DeleteButton id={feedback.id} />}
        {!isDisabled && (
          <Button kind="secondary" type="submit">
            Зберегти
          </Button>
        )}
      </div>
    </Form>
  )
}
