import { Button, Form } from 'components'
import { FieldInput } from 'components/fields'
import { Flex } from 'components/layouts'

import {
  selectExpertiseLoading,
  selectFilters,
} from 'features/ExpertiseList/selectors'

import { useAppDispatch, useAppSelector } from 'hooks'

import { setFilters } from '../../slice'
import { ExpertiseListSliceFilters } from '../../types'

import styles from './styles.module.scss'

const emptyFormValues = { search: '', needModeration: false }

export const Filters = () => {
  const dispatch = useAppDispatch()
  const { needModeration, search } = useAppSelector(selectFilters)
  const loading = useAppSelector(selectExpertiseLoading)

  return (
    <Form<ExpertiseListSliceFilters>
      initialValues={{
        search,
        needModeration,
      }}
      onSubmit={({ search, needModeration }) => {
        dispatch(setFilters({ search, needModeration }))
      }}
      enableReinitialize // with this, `initialValues` state will be update after submit, thus resetting a `dirty` flag
      loading={loading}
    >
      {({ dirty, resetForm }) => (
        <Flex center>
          <FieldInput
            name="search"
            placeholder="Введіть ID ЄДЕБО ОП для пошуку експертизи"
            containerClassName={styles.search_wrapper}
            className={styles.search}
          />
          <FieldInput type="checkbox" name="needModeration" label="Модерація" />
          <Button kind="secondary" type="submit" disabled={!dirty}>
            Застосувати фільтри
          </Button>
          <Button
            kind="secondary"
            onClick={() => {
              dispatch(setFilters(emptyFormValues))
              resetForm({ values: emptyFormValues })
            }}
          >
            Скинути усі фільтри
          </Button>
        </Flex>
      )}
    </Form>
  )
}
