import { useEffect, useMemo } from 'react'

import clsx from 'clsx'

import { selectIsExpertUser } from 'features/User'

import { useAppDispatch, useAppSelector } from 'hooks'

import { useFieldsByTabs } from '../../hooks'
import { setActiveTab } from '../../slice'

import styles from './styles.module.scss'

export const FEEDBACK_TAB = 'Відгуки по експерту'

export const Tabs = () => {
  const dispatch = useAppDispatch()
  const { activeTab } = useAppSelector(s => s.expertForm)
  const isExpert = useAppSelector(selectIsExpertUser)
  const groups = useFieldsByTabs()
  const tabNames = useMemo(() => {
    const tabNames = Object.keys(groups)
    if (!isExpert) tabNames.push(FEEDBACK_TAB)
    return tabNames
  }, [groups, isExpert])

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab(tabNames[0]))
    }
  }, [tabNames, activeTab, dispatch])

  const handleItemClick = (tabName: string) => () =>
    dispatch(setActiveTab(tabName))

  return (
    <div className={styles.tabs}>
      {tabNames.map(name => (
        <div
          className={clsx(styles.tab_item, {
            [styles.tab_item__active]: name === activeTab,
          })}
          key={name}
          onClick={handleItemClick(name)}
        >
          {name}
        </div>
      ))}
    </div>
  )
}
