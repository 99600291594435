import { ExpertiseTable } from './components/ExpertiseTable'
import { Filters } from './components/Filters'

export const ExpertiseList = () => {
  return (
    <>
      <Filters />
      <ExpertiseTable />
    </>
  )
}
