import { ExpertiseListSliceState } from './types'

export const selectExpertise: AppSelector<
  ExpertiseListSliceState['expertise']
> = state => state.expertiseList.expertise

export const selectFilters: AppSelector<ExpertiseListSliceState['filters']> =
  state => state.expertiseList.filters

export const selectExpertiseLoading: AppSelector<
  ExpertiseListSliceState['loading']
> = state => state.expertiseList.loading
