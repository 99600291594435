import { Form } from 'components/Form'
import { FieldOptionsProvider } from 'components/fields'

import { ExpertFormStatus, ExpertFormValuesState } from 'types'

import { useFieldsByTabs, useIsFormDisabled } from '../../hooks'
import { Feedback } from '../Feedback'
import { FieldGroupsList } from '../FieldGroup'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { FEEDBACK_TAB, Tabs } from '../Tabs'

import { ErrorScroller } from './ErrorScroller'
import { FormSchema, useInitForm, useSubmitHandler } from './lib'

import styles from './styles.module.scss'

export interface ExpertFormProps {
  id: string
}

export const ExpertForm = ({ id }: ExpertFormProps) => {
  const { initialValues, activeTab, userId } = useInitForm(id)
  const isDisabled = useIsFormDisabled()
  const fieldGroups = useFieldsByTabs()
  const { onSubmit, setSubmitStatus } = useSubmitHandler()

  const isShowFields = activeTab && activeTab !== FEEDBACK_TAB
  const isShowFeedback = activeTab === FEEDBACK_TAB && userId

  return (
    <>
      <Form<ExpertFormValuesState>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        className={styles.form}
        validationSchema={FormSchema}
        //
        // Disabled for performance reasons.
        // Formik text inputs lags HORRIBLY with on-change validation, with the amount of fields we have here.
        // And it CAN NOT be simply fixed by any "FastField" or whatever else garbage formik provides.
        //
        // If you're curios:
        // @see https://github.com/jaredpalmer/formik/blob/b9cc2536a1edb9f2d69c4cd20ecf4fa0f8059ade/packages/formik/src/Formik.tsx#L573-L588
        // and look at what `validateFormWithHighPriority` does – does on *Every Single Value Change*.
        validateOnChange={false}
      >
        {({ submitForm }) => (
          <FieldOptionsProvider
            /* Every field in this form is standalone and doesn't depend on other fields (so far).
          Thus, we can and should use <FastField> instead of normal one.
           Taking into account the amount of fields in this form, it gives a visually noticeable performance boost.  */
            fast
          >
            <Header />

            <Tabs />

            <fieldset disabled={isDisabled}>
              {isShowFields && (
                <FieldGroupsList fields={fieldGroups[activeTab]} />
              )}
            </fieldset>
            <ErrorScroller />
            {!isShowFeedback && (
              <Footer
                onSendToReview={() => {
                  setSubmitStatus(ExpertFormStatus.sent)
                  return submitForm()
                }}
              />
            )}
          </FieldOptionsProvider>
        )}
      </Form>
      {isShowFeedback && <Feedback userId={userId} />}
    </>
  )
}
