import React, { useContext } from 'react'

import { ErrorMessage, IErrorMessageProps } from '../ErrorMessage'

import { FormErrorContext } from './lib'

export function useFormSubmitError() {
  return useContext(FormErrorContext)
}

export const FormSubmitError = React.memo(function FormSubmitError(
  props: Omit<IErrorMessageProps, 'children'>
) {
  const error = useFormSubmitError()
  return <ErrorMessage {...props}>{error}</ErrorMessage>
})
