import React, { CSSProperties, ReactHTML } from 'react'

export interface FlexProps {
  tag?: keyof ReactHTML
  column?: boolean
  gap?: number
  center?: true | 'v' | 'h'
  wrap?: boolean | CSSProperties['flexWrap']
  inline?: boolean
  className?: string
  style?: CSSProperties
}

export const Flex: React.FC<FlexProps> = props => {
  const {
    children,
    tag: Tag = 'div',
    column = false,
    inline = false,
    gap = 12,
    center,
    wrap = true,
    className,
    style,
  } = props

  return (
    <Tag
      style={{
        ...style,
        ...getRowCenterStyle({ center, column }),
        display: inline ? 'inline-flex' : 'flex',
        gap,
        flexDirection: column ? 'column' : 'row',
        flexWrap: wrap === true ? 'wrap' : wrap === false ? 'nowrap' : wrap,
      }}
      className={className}
    >
      {children}
    </Tag>
  )
}

// ---

const CENTER_JUSTIFY: CSSProperties = { justifyContent: 'center' }
const CENTER_ALIGN: CSSProperties = { alignItems: 'center' }

function getRowCenterStyle(params: Pick<FlexProps, 'column' | 'center'>) {
  const { column, center } = params
  if (center === undefined) {
    return undefined
  }

  if (center === true) {
    return { ...CENTER_JUSTIFY, ...CENTER_ALIGN }
  }

  if (column) {
    return center === 'v' ? CENTER_JUSTIFY : CENTER_ALIGN
  }

  // row
  return center === 'h' ? CENTER_JUSTIFY : CENTER_ALIGN
}
