import { LinkButton } from 'components'

import routes from 'routes'

import styles from './styles.module.scss'

interface DocumentsListProps {
  title: string
  documents: string[]
  start?: number
}

const DOC_LIST = [
  'Мотиваційний лист.',
  'Резюме.',
  'Документ, що підтверджує ваш статус науково-педагогічного, наукового працівника або здобувача вищої освіти (довідка з місця роботи/навчання з печаткою та датою не раніше ніж місяць до дня подання цієї заяви).',
]
const SCIENTIST_DOC_LIST = [
  'Документи, що підтверджують спеціальність /спеціальності (науковий ступінь, вчене звання, диплом).',
]

export const Documents = () => {
  return (
    <main className={styles.main}>
      <h1 className={styles.header}>
        Дякуємо Вам за виявлене бажання долучитися до команди експертів
        Національного агентства із забезпечення якості вищої освіти.
      </h1>
      <DocumentsList
        title="Вам необхідно подати такі документи:"
        documents={DOC_LIST}
      />
      <DocumentsList
        title="Тільки для науково-педагогічних, наукових працівників:"
        documents={SCIENTIST_DOC_LIST}
        start={DOC_LIST.length + 1}
      />
      <div className={styles.rules}>
        <strong>
          Звертаємо увагу та те, що за кожною обраною спеціальністю потрібно
          бути підтвердити досвід роботи!
        </strong>
        <p>
          Детально з Порядком відбору експертів Ви можете ознайомитися тут -
          <a href="https://bit.ly/3jGq0RD" target="_blank" rel="noreferrer">
            https://bit.ly/3jGq0RDi
          </a>
        </p>
      </div>
      <div className={styles.link_wrapper}>
        <LinkButton kind="primary" to={routes.registration}>
          Погоджуюся та хочу
          <br /> подати анкету
        </LinkButton>
      </div>
    </main>
  )
}

function DocumentsList({ title, documents, start = 1 }: DocumentsListProps) {
  return (
    <>
      <div className={styles.title}>{title}</div>
      <ol start={start} className={styles.doc_list}>
        {documents.map((doc, i) => (
          <li key={i}>{doc}</li>
        ))}
      </ol>
    </>
  )
}
