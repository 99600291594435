import routes from 'routes'

import { ReferrerState } from '../HistoryReferrer'

import { IExpertUser, IPrivilegedUser, IUser, UserGroup } from './types'

export const isGuestUser = (user: IUser): boolean => user.id === undefined

export const isExpertUser = (user: IUser): user is IExpertUser =>
  user.groups.includes(UserGroup.expert) && !!user.card.id

export const isModeratorUser = (user: IUser): user is IPrivilegedUser =>
  user.groups.includes(UserGroup.supervisor) ||
  user.groups.includes(UserGroup.specialist)

export const isSupervisorUser = (user: IUser): user is IPrivilegedUser =>
  user.groups.includes(UserGroup.supervisor)

// ---

// where to go after login
export function resolveAuthReferrerPath(
  { ref, force }: ReferrerState,
  user: IUser
): string {
  if (ref && force) {
    return ref.pathname
  }

  return getUserHomePage(user)
}

export function getUserHomePage(user: IUser) {
  if (isExpertUser(user)) {
    return routes.expertCab
  }

  return routes.registry
}
