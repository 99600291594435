import { useCallback } from 'react'

import { DraftModeratorControls } from 'components/DraftModeratorControls'

import { selectExpertForm } from 'features/ExpertForm/selectors'

import { useAppDispatch, useAppSelector } from 'hooks'

import { applyChange } from '../../thunks'

export function ExpertCardDraftControls({ id }: { id: string }) {
  const { loading } = useAppSelector(selectExpertForm)
  const dispatch = useAppDispatch()
  const submit = useCallback(
    (accept: boolean, message?: string) => {
      dispatch(applyChange({ group_id: id, accept, message }))
    },
    [dispatch, id]
  )

  return (
    <>
      <DraftModeratorControls
        title="Погодження змін даних у картці особи"
        onSubmit={() => submit(true)}
        onReject={value => submit(false, value)}
        isDisable={loading}
      />
    </>
  )
}
