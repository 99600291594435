import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { FormikConfig } from 'formik'

type IFormErrorContext = string | undefined

export const FormErrorContext = createContext<IFormErrorContext>(undefined)

export function useFormSubmitErrorInternal<Values>(
  params: Pick<FormikConfig<Values>, 'onSubmit' | 'onReset'>
) {
  const { onSubmit, onReset } = params
  const [submitError, setSubmitError] = useState<IFormErrorContext>()

  const handleSubmit = useCallback<typeof onSubmit>(
    async (...args) => {
      try {
        await onSubmit?.(...args)
        setSubmitError(undefined)
      } catch (e) {
        // Can't check for `instanceof Error`, because `e` *may* be a plain object – in case it was caught in thunk:
        // @see https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        if (e instanceof Object && 'message' in e) {
          setSubmitError((e as Error).message)
        } else if (typeof e === 'string') {
          setSubmitError(e)
        } else {
          console.error(e)
        }
      }
    },
    [onSubmit]
  )

  const handleReset = useCallback<NonNullable<typeof onReset>>(
    (...args) => {
      onReset?.(...args)
      setSubmitError(undefined)
    },
    [onReset]
  )

  return {
    onSubmit: handleSubmit,
    onReset: handleReset,
    submitError,
    setSubmitError,
  }
}

export function useLatest<T>(x: T): T {
  const ref = useRef<T>(x)
  useEffect(() => {
    ref.current = x
  })
  return ref.current
}
