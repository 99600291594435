import React, { useRef } from 'react'

import clsx from 'clsx'

import { Button } from '../../Button'
import { Field } from '../BaseField'

import { FieldFileProps } from './types'

import styles from './styles.module.scss'

export const FieldFile = ({
  label,
  name,
  onChange,
  onDelete,
  fileSrc,
  disabled,
}: FieldFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const handleUpload = () => inputFileRef.current?.click()

  return (
    <div className={styles.file_wrapper}>
      {label && <label>{label}</label>}
      <div className={clsx(styles.file_wrapper, styles.file_block)}>
        {/* This will render a readonly text input. Intended for displaying file name.
        Accordingly to design, also should open file selection menu. */}
        <Field
          className={styles.input}
          readOnly
          onClick={handleUpload}
          name={name}
          disabled={disabled}
        />
        <input id={name} ref={inputFileRef} type="file" onChange={onChange} />
        <a
          className={styles.input}
          href={fileSrc}
          target="_blank"
          rel="noreferrer"
        >
          &#128065;
        </a>
        {/* "select file" btn */}
        <Button onClick={handleUpload} disabled={disabled}>
          &#128193;
        </Button>
        <Button
          className={styles.delete_btn}
          onClick={onDelete}
          disabled={disabled}
        >
          &#128465;
        </Button>
      </div>
    </div>
  )
}
