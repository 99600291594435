import { useCallback, useRef } from 'react'
import { ToastContentProps, toast } from 'react-toastify'

import * as Yup from 'yup'

import { useSuspenseErrorHandler } from 'components/Suspense'

import { REQUIRED_EMAIL_ERROR, REQUIRED_FIELD_ERROR } from 'const'
import { useAppDispatch, useAppSelector } from 'hooks'
import { ExpertFormStatus, ExpertFormValuesState } from 'types'

import { selectExpertForm } from '../../selectors'
import { getFormFields, submitForm } from '../../thunks'

import { ExpertFormProps } from './Form'

export function useInitForm(id: ExpertFormProps['id']) {
  const dispatch = useAppDispatch()
  const onError = useSuspenseErrorHandler()

  const state = useAppSelector(selectExpertForm)

  if (state.formId === id) return state

  const request = dispatch(getFormFields(id))
  throw request.unwrap().then(undefined, onError)
}

export const FormSchema = Yup.object().shape({
  current: Yup.object().shape({
    EmailAddr: Yup.array().of(
      Yup.object().shape({
        EmailAddr: Yup.string()
          .email(REQUIRED_EMAIL_ERROR)
          .required(REQUIRED_FIELD_ERROR),
      })
    ),
  }),
})

export function useSubmitHandler() {
  const dispatch = useAppDispatch()

  const refSubmitStatus = useRef<ExpertFormStatus>()

  const setSubmitStatus = (status: ExpertFormStatus) => {
    refSubmitStatus.current = status
  }

  const saveForm = useCallback(
    async (values: ExpertFormValuesState) => {
      const status = refSubmitStatus.current
      refSubmitStatus.current = undefined
      const promise = dispatch(
        submitForm({ values: values.current, status })
      ).unwrap()

      await toast.promise(
        promise,
        {
          pending: 'Обробка...',
          success: 'Дані збережено',
          error: {
            render: ({ data }: ToastContentProps<Error>) => {
              return `Помилка: ${data?.message}`
            },
          },
        },
        {
          toastId: 'save-expert-card',
          autoClose: 2000,
        }
      )
    },
    [dispatch]
  )

  return {
    onSubmit: saveForm,
    setSubmitStatus,
  }
}
