import PlotlyChart from 'react-plotlyjs-ts'

import { useExpertStatistic } from './hooks'
import { ChartProps, ChartSettings } from './types'

import styles from './styles.module.scss'

const layout = {
  height: 350,
  width: 550,
  showlegend: false,
}

const chartSettings: ChartSettings = {
  type: 'pie',
  textinfo: 'label+value+percent',
  textposition: 'outside',
  hoverinfo: 'none',
}

export const Charts = () => {
  const { educationStats, typesStats } = useExpertStatistic()

  return (
    <div>
      {typesStats && (
        <Chart
          header="Кількість експертів у реєстрі"
          data={[{ ...chartSettings, ...typesStats }]}
        />
      )}
      {educationStats && (
        <Chart
          header={
            <>
              Розподіл експертів з числа здобувачів вищої
              <br /> освіти за освітніми рівнями
            </>
          }
          data={[{ ...chartSettings, ...educationStats }]}
        />
      )}
    </div>
  )
}

function Chart({ header, data }: ChartProps) {
  return (
    <>
      <h2 className={styles.chart_header}>{header}</h2>
      <PlotlyChart data={data} layout={layout} />
    </>
  )
}
