import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector, useOnMount } from 'hooks'

import { ExpertiseData } from './components/ExpertiseData'
import { Reviews } from './components/Reviews'
import { selectExpertise, selectExpertiseLoading } from './selectors'
import { clearExpertise } from './slice'
import { getExpertiseById, getFeedback } from './thunks'

export const ExpertiseReviews = () => {
  const { id } = useParams<{ id: string }>()
  const expertise = useAppSelector(selectExpertise)
  const isLoading = useAppSelector(selectExpertiseLoading)
  const dispatch = useAppDispatch()
  useOnMount(() => {
    dispatch(getExpertiseById(id))
    dispatch(getFeedback({ expertise_id: id }))
    return () => {
      dispatch(clearExpertise())
    }
  })

  if (isLoading) return <>Завантажуємо відгуки ...</>

  return (
    <>
      {expertise && <ExpertiseData expertise={expertise} />}
      <Reviews />
    </>
  )
}
