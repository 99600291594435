import { useEffect, useState } from 'react'

import * as API from 'api/ExpertStats'

import { StatisticStateProps } from './types'

export const useExpertStatistic = () => {
  const [educationStats, setEducationStats] =
    useState<StatisticStateProps | null>(null)
  const [typesStats, setTypesStats] = useState<StatisticStateProps | null>(null)

  useEffect(() => {
    API.getEducationStats().then(data => setEducationStats(data))
    API.getExpertTypesStats().then(data => setTypesStats(data))
  }, [])

  return { educationStats, typesStats }
}
