import api from 'api'

import { IUser, IUserResponse } from '../types'

import { runMicrosoftLogin } from './microsoft-login'

export async function getUser(): Promise<IUser> {
  const { data } = await api.get<IUserResponse>('/users/current')
  return data
}

export async function logout() {
  await api.post('/logout')
  return undefined
}

export async function microsoftLogin() {
  const request = api.get<{ authorization_url: string }>('/authorization_url/')
  // Remember that ms-login process MUST be run *synchronously* (speaking of opening login window),
  // to don't be blocked by browser's anti-malware policies.
  // @see https://utility.quantumobile.co/task?id=18319
  // Thus, you MUST NOT use `await` before running this func:
  return runMicrosoftLogin(request.then(x => x.data.authorization_url))
}
