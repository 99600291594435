import {
  ArrayFields,
  CheckboxFields,
  NullablePseudoBoolFields,
  PseudoBoolFields,
  RegistryFilterName,
} from '../types'

export function isArrayFieldName(x: RegistryFilterName): x is ArrayFields {
  return 'valid' in validateArrayFieldName(x)
}

export function isPseudoBoolFieldName(
  x: RegistryFilterName
): x is PseudoBoolFields {
  return 'valid' in validatePseudoBoolFieldName(x)
}

export function isNullablePseudoBoolFieldName(
  x: RegistryFilterName
): x is NullablePseudoBoolFields {
  return 'valid' in validateNullablePseudoBoolFieldName(x)
}

export function isCheckboxFieldName(
  x: RegistryFilterName
): x is CheckboxFields {
  return 'valid' in validateCheckboxFieldName(x)
}

// This weird-looking return type is a trick which allows to *guarantee*
// that *all* corresponding field names will be explicitly listed in code.
function validateArrayFieldName(
  x: RegistryFilterName
):
  | { valid: ArrayFields }
  | { invalid: Exclude<RegistryFilterName, ArrayFields> } {
  if (x === 'PersonSpeciality') {
    return { valid: x }
  }
  return { invalid: x }
}

function validatePseudoBoolFieldName(
  x: RegistryFilterName
):
  | { valid: PseudoBoolFields }
  | { invalid: Exclude<RegistryFilterName, PseudoBoolFields> } {
  if (
    x === 'HaveAdmStateSecret' ||
    x === 'IsAccredPhD' ||
    x === 'IsTrainerRecommendHead' ||
    x === 'IsTrainerRecommendExpert'
  ) {
    return { valid: x }
  }
  return { invalid: x }
}

function validateNullablePseudoBoolFieldName(
  x: RegistryFilterName
):
  | { valid: NullablePseudoBoolFields }
  | { invalid: Exclude<RegistryFilterName, NullablePseudoBoolFields> } {
  if (x === 'RecomInclToRegister') {
    return { valid: x }
  }
  return { invalid: x }
}

function validateCheckboxFieldName(
  x: RegistryFilterName
):
  | { valid: CheckboxFields }
  | { invalid: Exclude<RegistryFilterName, CheckboxFields> } {
  if (
    x === 'is_NPP' ||
    x === 'is_Edu' ||
    x === 'EngLangLevel' ||
    x === 'FirstAllow' ||
    x === 'ReadyCertJunBak' ||
    x === 'ReadyCertBak' ||
    x === 'ReadyCertMag' ||
    x === 'ReadyCertPhilDoc'
  ) {
    return { valid: x }
  }
  return { invalid: x }
}
