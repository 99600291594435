import { ContactLinkProps, ContactsList } from './types'

import styles from './styles.module.scss'

const CONTACTS_LIST: ContactsList = [
  {
    title: 'Відділ супроводу експертів:',
    email: 'support_experts@naqa.gov.ua',
  },
  {
    title: 'Технічна підтримка ІТ-систем Нацагентства:',
    email: 'support@naqa.gov.ua',
  },
  {
    title: 'Акредитаційна система Національного агентства:',
    link: 'https://office.naqa.gov.ua',
  },
]
export const Contacts = () => {
  return (
    <div className={styles.contacts}>
      <h2 className={styles.header}>Контакти</h2>
      {CONTACTS_LIST.map(props => (
        <div className={styles.contact} key={props.title}>
          <span>{props.title}</span>
          {'email' in props ? (
            <ContactLink href={props.email} asEmail />
          ) : (
            <ContactLink href={props.link} />
          )}
        </div>
      ))}
    </div>
  )
}

function ContactLink({ href, asEmail }: ContactLinkProps) {
  return (
    <a
      className={styles.link}
      href={asEmail ? `mailto:${href}` : href}
      target="_blank"
      rel="noreferrer"
    >
      {href}
    </a>
  )
}
