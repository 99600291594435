import { ConnectedRouter as Router } from 'connected-react-router'
import React from 'react'
import {
  ErrorBoundary,
  setDefaultErrorBoundaryFallback,
  setDefaultErrorBoundaryOptions,
} from 'react-app-error-boundary'
import { ToastContainer, toast } from 'react-toastify'

import { ErrorMessage, Suspense } from 'components'
import { MainLayout } from 'components/layouts'

import { ReferrerTracker } from 'features/HistoryReferrer'
import { SessionChecker } from 'features/User'

import { history } from 'routes'

import { AppRouter } from './AppRouter'

function App() {
  return (
    <Guard>
      <SessionChecker>
        <Router history={history}>
          <ReferrerTracker>
            <MainLayout>
              <AppRouter />
              <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                closeOnClick
              />
            </MainLayout>
          </ReferrerTracker>
        </Router>
      </SessionChecker>
    </Guard>
  )
}

const Guard: React.FC = props => {
  const { children } = props
  return (
    <ErrorBoundary allowDevErrorOverlay>
      <Suspense>{children}</Suspense>
    </ErrorBoundary>
  )
}

setDefaultErrorBoundaryFallback(({ error }) => (
  <ErrorMessage>{error.message}</ErrorMessage>
))

setDefaultErrorBoundaryOptions({
  logCaughtErrors: false,
})

export default App
