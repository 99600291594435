import { useMemo } from 'react'

import { useFormikContext } from 'formik'
import { groupBy } from 'lodash'

import {
  isExpertUser,
  isGuestUser,
  selectIsGuestUser,
  useUser,
} from 'features/User'

import { useAppSelector } from 'hooks'
import { ExpertFormStatus, ExpertFormValuesState } from 'types'

import { selectExpertForm } from './selectors'

/**
 * @see https://docs.google.com/document/d/1Cd2rORT_i2pdX-WY-VQRzavi-Q5b0Bwr7W-pOGuhWo8/edit#heading=h.yz5wvzh9bonh
 */
export const useIsFormDisabled = (): boolean => {
  const user = useUser()
  const { formStatus, formId } = useAppSelector(s => s.expertForm)

  if (isGuestUser(user)) {
    return formStatus !== ExpertFormStatus.new
  }

  if (isExpertUser(user)) {
    // TODO: formId should NOT be undefined. Form page should only be available by id.
    const isOwnCard = formId === undefined || formId === user.card.id
    if (!isOwnCard) return true

    if (
      formStatus !== ExpertFormStatus.new &&
      formStatus !== ExpertFormStatus.expert
    ) {
      return true
    }
  }

  // TODO: implement full logic for other user roles
  return false
}

export function useExpertForm() {
  return useFormikContext<ExpertFormValuesState>()
}

export function useAllowedFields() {
  const { fieldSchemas: schemas, initialValues } =
    useAppSelector(selectExpertForm)
  const isGuest = useAppSelector(selectIsGuestUser)
  const isScientist =
    initialValues.current.StudentScientist[0].StudentScientist === '1'

  return useMemo(
    () =>
      schemas
        .filter(x => (isGuest ? x.is_in_application : true))
        .filter(x => (isScientist ? x.is_npp : x.is_applicant)),
    [isGuest, schemas, isScientist]
  )
}

export function useFieldsByTabs() {
  const fields = useAllowedFields()
  return useMemo(() => groupBy(fields, x => x.screen_section_name), [fields])
}
