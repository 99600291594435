import { Page } from 'components/layouts'

import { PersonsRegistry } from 'features/PersonsRegistry'

export function PersonsRegistryPage() {
  return (
    <Page title="Реєстр осіб">
      <PersonsRegistry />
    </Page>
  )
}
