import React from 'react'

import { Button } from 'components'
import { Form } from 'components/Form'
import { Flex } from 'components/layouts'

import { SystemDictionaryName } from 'features/Dicts'

import { useAppDispatch, useAppSelector } from 'hooks'

import { fetchRegistry } from '../../thunks'
import { RegistryFiltersFormValues } from '../../types'

import { BtnDisableAll } from './BtnDisableAll'
import { ExportButton } from './ExportButton'
import { FilterField, FilterFieldArray } from './FilterField'
import {
  Checkbox,
  InputDate,
  InputScore,
  InputSearch,
  SelectBool,
  SelectDict,
  SelectExpertCardStatus,
  SelectZVO,
} from './controls'
import { initialValues } from './lib'

import styles from './form.module.scss'

export function RegistryFiltersForm() {
  const loading = useAppSelector(x => x.personsRegistry.loading)
  const dispatch = useAppDispatch()

  return (
    <Form<RegistryFiltersFormValues>
      onSubmit={v => {
        return dispatch(fetchRegistry(v)).unwrap()
      }}
      initialValues={initialValues}
      className={styles.form}
      loading={loading}
    >
      <Flex column gap={8}>
        <Flex center>
          <FilterField
            name="q"
            forceEnabled
            style={{
              width: '50%',
            }}
          >
            <InputSearch />
          </FilterField>
        </Flex>

        <div>
          <div>Спеціальність</div>
          <Flex>
            <FilterFieldArray name="PersonSpeciality.0">
              <SelectDict dict={SystemDictionaryName.specialities_edu} />
            </FilterFieldArray>

            <FilterFieldArray label="та" name="PersonSpeciality.1">
              <SelectDict dict={SystemDictionaryName.specialities_edu} />
            </FilterFieldArray>

            <FilterFieldArray label="та" name="PersonSpeciality.2">
              <SelectDict dict={SystemDictionaryName.specialities_edu} />
            </FilterFieldArray>
          </Flex>
        </div>
        <Row>
          <Flex>
            <FilterField
              label="Провідна спеціальність"
              name="IsPersonSpeciality"
            >
              <SelectDict dict={SystemDictionaryName.specialities_edu} />
            </FilterField>
            <FilterField
              label="Спеціалізація для певних спеціальностей"
              name="Specialization"
            >
              <SelectDict dict={SystemDictionaryName.specialization} />
            </FilterField>
          </Flex>
        </Row>

        <Row>
          <Flex>
            <FilterField label="Статус експерта НПП" name="ExpertStatusNPP">
              <SelectDict dict={SystemDictionaryName.science_nomenclature} />
            </FilterField>
            <FilterField label="або Здобувача" name="ExpertStatusStudent">
              <SelectDict dict={SystemDictionaryName.edu_degree} />
            </FilterField>
          </Flex>
        </Row>
        <Row>
          <FilterField label="ЗВО (всі крім зазначеного)" name="ZVOName">
            <SelectZVO />
          </FilterField>
        </Row>

        <Row>
          <Flex>
            <FilterField label="Статус картки особи" name="status">
              <SelectExpertCardStatus />
            </FilterField>

            <FilterField label="НПП" name="is_NPP">
              <Checkbox />
            </FilterField>

            <FilterField label="Здобувач" name="is_Edu">
              <Checkbox />
            </FilterField>
          </Flex>
        </Row>

        <Row>
          <FilterField label="Допуск до держтаємниці" name="HaveAdmStateSecret">
            <SelectBool />
          </FilterField>

          <FilterField label="Участь в акредитації ОНП PhD" name="IsAccredPhD">
            <SelectBool />
          </FilterField>

          <FilterField label="Не входить у ГЕР" name="IsGERmember">
            <SelectDict dict={SystemDictionaryName.expert_groups} />
          </FilterField>
        </Row>

        <Row>
          <Flex wrap={false}>
            <FilterField
              label="Володіння англійською мовою"
              name="EngLangLevel"
            >
              <Checkbox />
            </FilterField>

            <FilterField label="Рекомендація перший допуск" name="FirstAllow">
              <Checkbox />
            </FilterField>
          </Flex>

          <FilterField label="Дата першого допуску" name="FirstAllowDate">
            <InputDate />
          </FilterField>

          <FilterField
            label="Оцінка Прометеус тестування"
            name="IsOnlineCourseScore"
          >
            <InputScore />
          </FilterField>
        </Row>

        <Row>
          <FilterField label="Дата додаткового тренінгу" name="AddTrainingDate">
            <InputDate />
          </FilterField>

          <FilterField
            label="Рекомендація тренерів щодо даної особи, як керівника експертної групи"
            name="IsTrainerRecommendHead"
          >
            <SelectBool />
          </FilterField>

          <FilterField
            label="Рекомендація тренерів щодо даної особи, як експерта"
            name="IsTrainerRecommendExpert"
          >
            <SelectBool />
          </FilterField>
        </Row>

        <Row>
          <FilterField
            label="Оцінка за додатковий тренінг"
            name="AddTrainingScore"
          >
            <InputScore />
          </FilterField>

          <FilterField
            label="Рекомендація включення в реєстр експертів"
            name="RecomInclToRegister"
          >
            <SelectBool nullable />
          </FilterField>

          <FilterField
            label="Дата включення в реєстр експертів"
            name="RecomInclToRegisterDate"
          >
            <InputDate />
          </FilterField>
        </Row>
        <Flex>
          <div className={styles.ready_title}>
            Готовий брати участь в акредитації освітніх програм за такими
            рівнями:
          </div>
          <div>
            <FilterField label="Молодший бакалавр" name="ReadyCertJunBak">
              <Checkbox />
            </FilterField>
            <FilterField label="Магістр" name="ReadyCertMag">
              <Checkbox />
            </FilterField>
          </div>
          <div>
            <FilterField label="Бакалавр" name="ReadyCertBak">
              <Checkbox />
            </FilterField>
            <FilterField label="Доктор філософії" name="ReadyCertPhilDoc">
              <Checkbox />
            </FilterField>
          </div>
        </Flex>
      </Flex>

      <Flex className={styles.footer}>
        <BtnDisableAll />
        <Button kind="secondary" type="submit">
          Застосувати фільтри
        </Button>
        <ExportButton />
      </Flex>
    </Form>
  )
}

const Row: React.FC = props => {
  const { children } = props
  return <div className={styles.row}>{children}</div>
}
