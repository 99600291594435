import { createAsyncThunk } from '@reduxjs/toolkit'
import { pick } from 'lodash'

import { isExpertUser, selectUser } from 'features/User'

import * as API from 'api'
import {
  ApplyChangePayload,
  ExpertFormDocumentPayload,
  ExpertFormPayload,
  ExpertFormPopulatedDocument,
  ExpertFormResponse,
  ExpertFormStatus,
  ExpertFormValues,
  FormFieldValue,
} from 'types'
import { normalizeDateInputValue, xmap } from 'utils'

import {
  addFieldsetIds,
  isDocumentField,
  isPopulatedDocumentField,
} from './lib'
import { selectExpertForm } from './selectors'

const formatFormResponse = createAsyncThunk(
  'expertForm/formatFormResponse',
  async (data: ExpertFormResponse, { getState }) => {
    const {
      status,
      fields,
      suggested,
      tobeadded = {},
      tobemodified = {},
      toberemoved = {},
      ...rest
    } = data

    const state = getState() as RootState
    const user = selectUser(state)
    const isExpert = isExpertUser(user) && status === ExpertFormStatus.expert
    const values = isExpert ? suggested : fields

    const normalizedValues = await traverseForm(values, normalizeFormFieldValue)

    return {
      ...rest,
      values: normalizedValues,
      status,
      draft: {
        tobeadded,
        tobemodified,
        toberemoved,
      },
    }
  }
)

export const getFormFields = createAsyncThunk(
  'expertForm/getFields',
  async (id: ExpertFormPayload['id'] | undefined, { dispatch }) => {
    const { data: schemas } = await API.expertForm.getFieldsSchema()

    if (id === undefined) {
      return {
        schemas,
        values: {} as ExpertFormValues,
        draft: undefined,
        status: undefined,
        id: undefined,
        user_id: undefined,
      }
    }

    const { data } = await API.expertForm.getUserCard(id)
    const formatted = await dispatch(formatFormResponse(data)).unwrap()
    return { ...formatted, schemas }
  }
)

// ---

interface ExpertFormSubmitParams {
  values: ExpertFormValues
  // Status *may* be set on submit (new -> sent). By default, current status is sent as-is.
  status?: ExpertFormStatus
}

export const submitForm = createAsyncThunk(
  'expertForm/submitForm',
  async (params: ExpertFormSubmitParams, { getState, dispatch }) => {
    const state = getState() as RootState
    const user = selectUser(state)
    const { formStatus: currentStatus, formId } = selectExpertForm(state)

    const { values: rawValues, status = currentStatus } = params

    const values = await traversePopulatedDocs(
      addFieldsetIds(rawValues),
      submitDocField
    )

    const isExpert = isExpertUser(user)
    const isOwnCard = formId === user.card.id
    // User may belong to "experts" group, but his profile card may not be approved.
    // Meaning, he did register as "candidate to experts", and is at verification stage yet.
    const isExpertStatusApproved = currentStatus === ExpertFormStatus.expert
    // @see https://utility.quantumobile.co/task?id=17953
    const isNeedPreModeration = isOwnCard && isExpert && isExpertStatusApproved

    const { data } = await API.expertForm.submitFields({
      id: formId,
      status,
      ...(isNeedPreModeration ? { suggested: values } : { fields: values }),
    })

    return dispatch(formatFormResponse(data)).unwrap()
  }
)

export const updateCardStatus = createAsyncThunk(
  'expertForm/updateStatus',
  async (status: ExpertFormStatus, { getState }) => {
    const state = getState() as RootState
    const id = selectExpertForm(state).formId

    if (!id) {
      throw new Error('Card id is undefined')
    }

    const { data } = await API.expertForm.updateStatus({
      id,
      status,
    })
    return data
  }
)

export const deleteForm = createAsyncThunk(
  'expertForm/deleteForm',
  API.expertForm.deleteForm
)

export const applyChange = createAsyncThunk(
  'expertForm/applyChange',
  async (payload: ApplyChangePayload, { getState }) => {
    const state = getState() as RootState
    const id = selectExpertForm(state).formId as string
    const {
      data: { fields, tobeadded, tobemodified, toberemoved },
    } = await API.expertForm.applyChange(payload, id)
    return { values: fields, draft: { tobeadded, tobemodified, toberemoved } }
  }
)

const submitDocField = async (doc: ExpertFormPopulatedDocument) => {
  const { data } = await API.expertForm.submitDocument(
    pick(doc, ['doc_file', 'doc_type', 'doc_src', 'doc_desc']),
    doc.id
  )
  const result: ExpertFormDocumentPayload = {
    ...doc,
    ...data,
    doc_file: undefined,
    doc_link: data.doc_file,
    doc_date: normalizeDateInputValue(data.doc_date),
    id: data.id,
  }
  return result
}

type TraversedForm<F extends Func> = Promise<
  ExpertFormValues<ReturnTypeMaybeAsync<F>>
>

const traverseForm = <F extends Func1<FormFieldValue>>(
  values: ExpertFormValues,
  fn: F
): TraversedForm<F> =>
  xmap(values, group => xmap(group, fieldset => xmap(fieldset, fn)))

const traversePopulatedDocs = <F extends Func1<ExpertFormPopulatedDocument>>(
  values: ExpertFormValues,
  fn: F
): TraversedForm<F | Identity<FormFieldValue>> =>
  traverseForm(values, x => (isPopulatedDocumentField(x) ? fn(x) : x))

function normalizeFormFieldValue(x: FormFieldValue) {
  if (isDocumentField(x) && x.doc_date !== undefined) {
    // This is primarily for old data, which got to db due to invalid inputs format.
    // Normally, you shouldn't need this.
    return { ...x, doc_date: normalizeDateInputValue(x.doc_date) }
  }
  return x
}
