import { ReactNode } from 'react'

import { LinkButton } from 'components'

import styles from './styles.module.scss'

interface LinkButtonProps {
  link: string
  description: string
  children: ReactNode
}

export const LandingLinkButton = (props: LinkButtonProps) => {
  const { link, description, children } = props
  return (
    <div className={styles.link_wrapper}>
      <LinkButton kind="primary" className={styles.link_btn} to={link}>
        {children}
      </LinkButton>
      <p>{description}</p>
    </div>
  )
}
