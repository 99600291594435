import { FieldProps as FormikFieldProps } from 'formik'

import { AnySelectOption, Select, SelectOption } from 'components/Select'

import { Field } from '../BaseField'

import { FieldSelectProps } from './types'

export function FieldSelect<T extends AnySelectOption = SelectOption>(
  props: FieldSelectProps<T>
) {
  const { name, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form, meta }: FormikFieldProps) => (
        <Select
          {...rest}
          name={name}
          onChange={option => form.setFieldValue(name, option?.value ?? '')}
          value={field.value}
          error={meta.touched ? meta.error : undefined}
        />
      )}
    </Field>
  )
}
