import clsx from 'clsx'

import { FieldSetDataSource } from 'types'

import { ExpertCardDraftControls } from '../../../ExpertCardDraftControls'
import { Field } from '../../../Field'
import { getGroupParams, useEntryIndex } from '../../lib'
import { FieldGroup } from '../../types'

import { RepeatableGroupControls } from './RepeatableGroupControls'

import styles from './styles.module.scss'

export interface FieldSetProps {
  group: FieldGroup
  id: string
  draft?: boolean
  dataSource?: FieldSetDataSource
  readOnly?: boolean
}

export const FieldSet = (props: FieldSetProps) => {
  const {
    group,
    id,
    dataSource = 'current',
    draft = false,
    readOnly = false,
  } = props
  const { group_name, screen_group_name, repeat_group } = getGroupParams(group)
  const entryIndex = useEntryIndex(dataSource, group_name, id)

  return (
    <div
      className={clsx(styles.fieldset, {
        [styles.draft]: draft,
      })}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          {/* TODO: maybe we should display `screen_field_name`, if `screen_group_name` is empty? */}
          {screen_group_name}
        </h3>

        {!draft && repeat_group && !readOnly && (
          <RepeatableGroupControls group_name={group_name} index={entryIndex} />
        )}

        {draft && <ExpertCardDraftControls id={id} />}
      </div>

      <fieldset className={styles.fields} disabled={readOnly}>
        {group.map(field_schema => (
          <div
            key={field_schema.id}
            title={field_schema.screen_prompt?.toString()}
          >
            <Field
              {...field_schema}
              field_name={`${dataSource}.${group_name}.${entryIndex}.${field_schema.field_name}`}
            />
          </div>
        ))}
      </fieldset>
    </div>
  )
}
