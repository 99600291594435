import { omit } from 'lodash'

import api from 'api'

import {
  ExpertiseFeedback,
  ExpertiseFeedbackResponse,
  GetFeedbackParams,
} from './types'

const BASE_URL = '/cards/feedback'

export const getFeedback = (
  params: GetFeedbackParams = {} as GetFeedbackParams
) => {
  return api.get<ExpertiseFeedbackResponse[]>(BASE_URL, {
    params: {
      idEdboOp: 'expertise_id' in params ? params.expertise_id : undefined,
      to_expert: 'expert_id' in params ? params.expert_id : undefined,
    },
  })
}

export const addFeedback = (data: Omit<ExpertiseFeedback, 'id'>) => {
  return api.post<ExpertiseFeedbackResponse>(BASE_URL, data)
}

export const updateFeedback = (payload: ExpertiseFeedback) => {
  return api.patch<ExpertiseFeedbackResponse>(
    `${BASE_URL}/${payload.id}`,
    omit(payload, ['id', 'feedback_author_email'])
  )
}

export const deleteFeedback = ({
  id,
  message,
}: Pick<ExpertiseFeedback, 'id' | 'message'>) =>
  api.delete<ExpertiseFeedback['message']>(`${BASE_URL}/${id}`, {
    data: { message },
  })
