import * as Yup from 'yup'

import { REQUIRED_FIELD_ERROR } from 'const'

import { ExpertiseFeedback } from '../../../../types'

export const FormSchema = Yup.object().shape({
  scores: Yup.number()
    .max(100, 'Максимальна оцінка 100')
    .min(0, 'Мінімальна оцінка 0')
    .required(REQUIRED_FIELD_ERROR),
  source: Yup.string().required(REQUIRED_FIELD_ERROR),
  message: Yup.string().trim().required(REQUIRED_FIELD_ERROR),
})

export interface InitialValues {
  scores: string | number
  source: string
  message: string
  email: string
}

export interface ReviewFormProps {
  expertId?: number
  feedback: ExpertiseFeedback
  removable?: boolean
}
