import { ReactNode } from 'react'

import styles from './styles.module.scss'

interface FieldSetLayoutProps {
  left?: ReactNode
  right?: ReactNode
}

export function FieldSetLayout(props: FieldSetLayoutProps) {
  const { left, right } = props
  return (
    <div className={styles.root}>
      {left}
      {right}
    </div>
  )
}
