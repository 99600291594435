import { useState } from 'react'
import { generatePath, useHistory } from 'react-router'

import { Button, Modal, useConfirmModal } from 'components'

import { useAppDispatch, useAppSelector, useConfirm } from 'hooks'
import routes from 'routes'

import { selectExpertForm } from '../../selectors'
import { deleteForm } from '../../thunks'

import styles from './styles.module.scss'

export const CancelButton = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { formId } = useAppSelector(selectExpertForm)

  const [hasError, setHasError] = useState(false)
  const dialogResult = useConfirm({
    onClose() {
      if (hasError) {
        setHasError(false)
      }
    },

    onConfirm() {
      history.push(generatePath(routes.home))
    },
  })

  const dialogConfirm = useConfirmModal({
    title:
      'Ви впевнені, що дійсно бажаєте видалити введені дані? Це видалення даних не можна буде відмінити',
    btnCancelText: 'Скасувати',
    btnConfirmText: 'Видалити',

    async onConfirm() {
      if (formId) {
        try {
          await dispatch(deleteForm(formId)).unwrap()
        } catch (e) {
          setHasError(true)
        } finally {
          dialogResult.open()
        }
      }
    },
  })

  return (
    <>
      <Button kind="danger" onClick={dialogConfirm.open}>
        Видалити введення заявки
      </Button>

      {dialogConfirm.$element}

      <If condition={dialogResult.isOpen}>
        <Choose>
          <When condition={hasError}>
            <Modal title={ERROR_MESSAGE}>
              <Button onClick={dialogResult.cancel}>Ok</Button>
            </Modal>
          </When>

          <Otherwise>
            <Modal title="Дані було успішно видалено">
              <Button onClick={dialogResult.confirm}>Ok</Button>
            </Modal>
          </Otherwise>
        </Choose>
      </If>
    </>
  )
}

const ERROR_MESSAGE = (
  <div className={styles.error}>
    Не можливо видалити інформацію через технічне питання. Будь ласка,
    зверніться до технічної підтримки за допомогою електронного листа на адресу
    <a href="mailto:support@naqa.gov.ua" target="_blank" rel="noreferrer">
      support@naqa.gov.ua
    </a>
  </div>
)
