import { push } from 'connected-react-router'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { selectReferrer } from '../HistoryReferrer'

import * as API from './api'
import { resolveAuthReferrerPath } from './lib'
import { IUser } from './types'

export const getCurrentUser = createAsyncThunk('user/fetch', API.getUser)

// ---

type LoginParams = 'microsoft' /* | LoginFormFieldsToBeImplemented */

export const login = createAsyncThunk(
  'user/login',
  async (params: LoginParams, { getState, dispatch }): Promise<IUser> => {
    try {
      if (params === 'microsoft') {
        await API.microsoftLogin()
      } else {
        // to be implemented
      }
    } catch (e) {
      console.error(`Auth Failed: ${(e as Error).message}`)
    }

    // ---
    // finish auth: fetch user data and redirect user where he tried to access

    const state = getState() as RootState

    const user = await dispatch(getCurrentUser()).unwrap()

    const ref = selectReferrer(state)
    const returnTo = resolveAuthReferrerPath(ref, user)
    dispatch(push(returnTo))

    return user
  }
)

// ---

export const logout = createAsyncThunk('user/logout', API.logout)
