type FieldType =
  | 'LIST'
  | 'COLOR'
  | 'BOOLEAN'
  | 'DOCUMENT'
  | 'MULTILINE'
  | 'DATE'
  | 'NUMBER'
  | 'TEXT'
  | 'USERNAME'

export interface ExpertFormFieldSchema {
  id: number
  screen_section_name: string
  screen_group_name: string | null
  repeat_group: boolean
  screen_field_name: string
  field_name: string
  is_npp: boolean
  is_applicant: boolean
  // "application" here means "questionnaire" – a blank, a new form –
  // that is, a form available for guest users only
  is_in_application: boolean
  field_type: FieldType
  has_guide: boolean
  guide_name: string | null
  field_description: string | null
  screen_prompt: string | null
  is_active: boolean
}

export enum ExpertFormStatus {
  new = 'Нова',
  sent = 'Відправлено',
  candidate = 'Кандидат',
  expert = 'Експерт',
  rejected = 'Відхилено',
  removed = 'Вилучено',
}

// TODO: this type is not completely valid. Boolean fields will have a bool value.
export type FormFieldValue = string | ExpertFormDocumentPayload
export type FormFieldsSet<V = FormFieldValue> = Record<
  ExpertFormFieldSchema['field_name'] | 'id',
  V
>
export type FormFieldsGroup<V = FormFieldValue> = FormFieldsSet<V>[]

/* This is what will be in internal state of form component */
export type ExpertFormValues<V = FormFieldValue> = Record<
  string,
  FormFieldsGroup<V>
>

/**
 * @see https://docs.google.com/document/d/1sDFOJYlGkS-QkdZFoftpG0OnjQXQ3sUcG8x1gB-UBic/
 */
export interface ExpertFormDraftChanges {
  tobeadded: ExpertFormValues
  toberemoved: ExpertFormValues
  tobemodified: ExpertFormValues
}

export interface ExpertFormValuesState {
  current: ExpertFormValues
  changed: ExpertFormDraftChanges
}

type CastStateKey<K extends keyof ExpertFormValuesState> = K
export type FieldSetDataSource =
  | CastStateKey<'current'>
  | `${CastStateKey<'changed'>}.${keyof ExpertFormDraftChanges}`

// ---

interface BaseExpertFormShape {
  id?: string
  status: ExpertFormStatus
}

export interface ExpertFormResponse
  extends BaseExpertFormShape,
    ExpertFormDraftChanges {
  id: string
  fields: ExpertFormValues
  suggested: ExpertFormValues
  user_id: number
}

export type ExpertFormPayload = BaseExpertFormShape &
  // @see https://utility.quantumobile.co/task?id=17953
  ({ fields: ExpertFormValues } | { suggested: ExpertFormValues })

// ---

export interface ExpertFormDocumentPayload {
  doc_filename: string
  doc_file?: File
  doc_date?: string
  doc_type: string
  doc_src: string
  doc_desc?: string
  doc_link?: string
  doc_name: string
  id?: number
}

export interface ExpertFormPopulatedDocument extends ExpertFormDocumentPayload {
  doc_file: File
}

export interface ExpertFormDocumentSchema {
  doc_filename: string
  doc_file: string
  doc_date: string
  doc_type: string
  doc_src: string
  doc_desc: string
  id: number
}

export interface ApplyChangePayload {
  accept: boolean
  group_id: string
  message?: string
}
