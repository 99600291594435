import { useMemo, useRef } from 'react'
import ReactSelect from 'react-select'

import clsx from 'clsx'
import ReactSelectCreatable from 'react-select/creatable'

import { ErrorMessage } from '../ErrorMessage'

import { AnySelectOption, SelectOption, SelectProps } from './types'

import styles from './styles.module.scss'

const DEFAULT_NO_OPTIONS_MESSAGE = () => 'Не знайдено'

export function Select<T extends AnySelectOption = SelectOption>(
  props: SelectProps<T>
) {
  const { value, options } = useResolveOptionsValue<T>(props)
  const {
    label,
    isCreatable,
    placeholder = '',
    noOptionsMessage = DEFAULT_NO_OPTIONS_MESSAGE,
    disabled: isDisabled,
    wrapperClassName,
    wrapperStyle,
    error,
    ...rest
  } = props

  return (
    <div
      className={clsx(styles.select_wrapper, wrapperClassName)}
      style={wrapperStyle}
    >
      {label && <label className={styles.label}>{label}</label>}
      {isCreatable ? (
        <ReactSelectCreatable
          {...rest}
          className={styles.select}
          options={options}
          value={value}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPosition="fixed"
          menuShouldBlockScroll
          formatCreateLabel={value => value}
        />
      ) : (
        <ReactSelect
          {...rest}
          className={styles.select}
          options={options}
          value={value}
          placeholder={placeholder}
          isDisabled={isDisabled}
          // 'fixed' will make sure that opening menu won't cause appearing scroll on parent element
          menuPosition="fixed"
          // when menuPosition="fixed", menu won't follow input when page scrolls
          // so just deny to scroll page when menu opened
          menuShouldBlockScroll
          noOptionsMessage={noOptionsMessage}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

function useResolveOptionsValue<T extends AnySelectOption>(
  props: Pick<SelectProps<T>, 'options' | 'value' | 'isCreatable'>
) {
  const { value, options, isCreatable } = props
  const refCreatedOptions = useRef<SelectProps<T>['options']>([])

  const matchOption = (option: T) => option.value === value
  let selectedOption =
    options.find(matchOption) ?? refCreatedOptions.current.find(matchOption)
  if (isCreatable && selectedOption === undefined && value !== '') {
    const newOption = { value, label: value } as T
    // Created options are saved only as form values. They are never added to any dicts on server-side.
    // Thus, we don't need to export these new options anywhere and can store them in local state.
    // The only goal is to make react-select to understand a value not from options list.
    refCreatedOptions.current = refCreatedOptions.current.concat(newOption)
    selectedOption = newOption
  }

  const createdOptions = refCreatedOptions.current
  const resolvedOptions = useMemo(
    () => options.concat(createdOptions),
    [options, createdOptions]
  )
  return {
    options: resolvedOptions,
    value: selectedOption,
  }
}
