import { Select, SelectProps } from 'components/Select'
import { FieldSelect, FieldSelectProps } from 'components/fields'

import { SystemDictionaryName } from './types'
import { useDictOptions } from './useDict'

export interface BaseSelectDictProps {
  dict: SystemDictionaryName
  batch?: boolean
}

export interface SelectDictProps
  extends BaseSelectDictProps,
    Omit<SelectProps, 'options'> {}

export interface FieldSelectDictProps
  extends BaseSelectDictProps,
    Omit<FieldSelectProps, 'options'> {}

export function SelectDict(props: SelectDictProps) {
  const { dict: name, batch = false, ...rest } = props
  const { data: options, $loader } = useDictOptions(name, { batch })
  return $loader ?? <Select {...rest} options={options} />
}

export function FieldSelectDict(props: FieldSelectDictProps) {
  const { dict: name, batch = false, ...rest } = props
  const { data: options, $loader } = useDictOptions(name, { batch })
  return $loader ?? <FieldSelect {...rest} options={options} />
}
