import { useConfirm } from 'hooks'

import { ModalConfirm, ModalConfirmProps } from './Modal'

type UseConfirmOptions = Parameters<typeof useConfirm>[0]
type ModalProps = Omit<
  ModalConfirmProps,
  keyof UseConfirmOptions | 'children'
> & {
  content?: ModalConfirmProps['children']
}

export function useConfirmModal(params: UseConfirmOptions & ModalProps) {
  const { content, ...rest } = params
  const dialog = useConfirm(rest)
  return {
    ...dialog,
    $element: !dialog.isOpen ? null : (
      <ModalConfirm
        {...rest}
        onCancel={dialog.cancel}
        onConfirm={dialog.confirm}
      >
        {content}
      </ModalConfirm>
    ),
  }
}
