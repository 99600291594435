import { isEmpty } from 'lodash'

import { ExpertEducation, ExpertStats, ExpertTypes } from 'types'

import axiosInstance from './api'

const BASE_URL = '/cards/stats'
const PATHS = {
  educationStats: `${BASE_URL}/edu_level`,
  expertTypesStats: `${BASE_URL}/expert_types`,
}

export type parseStatisticProps =
  | ExpertStats<ExpertEducation>
  | ExpertStats<ExpertTypes>

function parseStatistic(data: parseStatisticProps) {
  if (isEmpty(data)) return null
  const labels = Object.keys(data)
  const values = Object.values(data).map(value => value.count)
  return { labels, values }
}

export const getEducationStats = async () => {
  const resp = await axiosInstance.get<ExpertStats<ExpertEducation>>(
    `${PATHS.educationStats}/`
  )
  return parseStatistic(resp.data)
}

export const getExpertTypesStats = async () => {
  const resp = await axiosInstance.get<ExpertStats<ExpertTypes>>(
    `${PATHS.expertTypesStats}/`
  )
  return parseStatistic(resp.data)
}
