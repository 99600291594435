import { Link, NavLink } from 'react-router-dom'

import clsx from 'clsx'

import { AuthOnly, UserGroup } from 'features/User'

import { ReactComponent as Logo } from 'logo.svg'
import routes from 'routes'

import { AuthMenu } from './AuthMenu'

import styles from './styles.module.scss'

export default function Header() {
  return (
    <header className={styles.header}>
      <Link to={routes.home}>
        <Logo width={80} />
      </Link>
      <Tagline />
      <Navigation />
      <AuthMenu />
    </header>
  )
}

function Tagline() {
  return (
    <div className={styles.tagline}>
      <div>Національне агентство із забезпечення якості вищої освіти </div>
      <div>National Agency for Higher Education Quality Assurance</div>
    </div>
  )
}

function Navigation() {
  return (
    <div className={styles.nav}>
      <AuthOnly roles={[UserGroup.expert]} mode="deny">
        <NavLink className={getLinkClass} to={routes.registry}>
          Реєстр Осіб
        </NavLink>
      </AuthOnly>

      <AuthOnly roles={[UserGroup.expert]}>
        <NavLink className={getLinkClass} to={routes.expertCab}>
          Кабiнет Експерта
        </NavLink>
      </AuthOnly>

      <AuthOnly roles={[UserGroup.expert]} mode="deny">
        <NavLink exact className={getLinkClass} to={routes.expertiseList}>
          Перелік експертиз
        </NavLink>
      </AuthOnly>
    </div>
  )
}

function getLinkClass(isActive: boolean) {
  return isActive ? clsx(styles.nav_link, styles.active) : styles.nav_link
}
