import { ReactElement, useMemo } from 'react'

import { FieldInput } from 'components/fields'

import { SystemDictionaryName, useDict } from 'features/Dicts'

import { useAppSelector } from 'hooks'

// TODO: create reusable <RadioGroup />
interface RadioGroupProps {
  dictName: SystemDictionaryName
}

export const OccupationFields = () => {
  return (
    <DictNameProvider>
      {name => <RadioGroup dictName={name} />}
    </DictNameProvider>
  )
}

function RadioGroup({ dictName }: RadioGroupProps) {
  const { $loader, data: dict } = useDict(dictName as SystemDictionaryName)

  const entries = useMemo(() => Object.entries(dict ?? {}), [dict])

  if ($loader) {
    return $loader
  }

  return (
    <>
      {entries.map(([k, v]) => (
        <FieldInput
          key={k}
          type="radio"
          label={v}
          name="StudentScientist"
          value={k}
        />
      ))}
    </>
  )
}

function DictNameProvider(props: {
  children: (name: SystemDictionaryName) => ReactElement
}) {
  const { children } = props
  const { fieldSchemas } = useAppSelector(x => x.expertForm)
  const dictName = useMemo(
    () =>
      fieldSchemas.find(({ field_name }) => field_name === 'StudentScientist')
        ?.guide_name,
    [fieldSchemas]
  )

  if (!dictName) {
    return null
  }

  return children(dictName as SystemDictionaryName)
}
