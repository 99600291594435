import { useMemo, useRef } from 'react'

import { SelectOption } from 'components/Select'

import { DictionariesAPI } from './api'
import { SystemDictionary, SystemDictionaryName } from './types'

function useDictSingle(name: SystemDictionaryName) {
  const { useQuery: useDict } = DictionariesAPI.endpoints.getDict
  return useDict(name)
}

function useDictBatch(name: SystemDictionaryName) {
  const { useQuery: useDicts } = DictionariesAPI.endpoints.getDicts
  const state = useDicts()
  return {
    ...state,
    data: state.data?.[name],
  }
}

function useInvariant<T>(x: T, label = 'value'): T {
  const ref = useRef(x)
  if (x !== ref.current) {
    throw new Error(
      `${label} is not supposed to change during component lifecycle.\nGot change from ${ref.current} to ${x}`
    )
  }
  return x
}

// ---

export function useDict(
  name: SystemDictionaryName,
  opts: { batch?: boolean } = {}
) {
  const { batch = false } = opts
  // use invariant, so we don't violate rules-of-hooks
  const useHook = useInvariant(batch, 'Option "batch"')
    ? useDictBatch
    : useDictSingle
  const state = useHook(name)
  return {
    ...state,
    $loader: renderDictLoader(state, name),
  }
}

export function useDictOptions(...args: Parameters<typeof useDict>) {
  const state = useDict(...args)
  return {
    ...state,
    data: useMemo(() => getSelectOptions(state.data), [state.data]),
  }
}

// ---

function renderDictLoader(
  props: { isLoading: boolean; data?: unknown },
  name: string
) {
  const { isLoading, data } = props

  if (isLoading) {
    return <>...завантажуємо словник...</>
  }

  if (!data) {
    return (
      <span style={{ fontStyle: 'italic', color: 'red' }}>
        Не вдалося завантажити словник '{name}'
      </span>
    )
  }

  return null
}

function getSelectOptions(
  dictionary: SystemDictionary['pairs'] | undefined
): SelectOption[] {
  if (dictionary === undefined) {
    return [
      {
        value: '',
        label: 'Зверніться до адміністратора для завантаження словника',
      },
    ]
  }

  return Object.entries(dictionary).map(([value, label]) => ({
    value,
    label,
  }))
}
