import { createAsyncThunk } from '@reduxjs/toolkit'

import { getExpertise } from 'api/Expertise'

import * as API from './api'
import { selectExpertiseFeedbacks } from './selectors'
import { ExpertiseFeedback, GetFeedbackParams } from './types'

export const getExpertiseById = createAsyncThunk(
  'ExpertiseReviews/getExpertiseById',
  async (id: string) => {
    const {
      data: { members, ...expertise },
    } = await getExpertise(id)
    return { members, expertise }
  }
)

export const getFeedback = createAsyncThunk(
  'ExpertiseReviews/getFeedback',
  async (params?: GetFeedbackParams) => {
    const { data } = await API.getFeedback(params)
    return data
  }
)
interface AddFeedbackParams {
  removedId: number
  payload: Omit<ExpertiseFeedback, 'id'>
}
export const addFeedback = createAsyncThunk(
  'ExpertiseReviews/addFeedback',
  async ({ removedId, payload }: AddFeedbackParams) => {
    const { data: feedback } = await API.addFeedback(payload)
    return { feedback, removedId }
  }
)

export const updateFeedback = createAsyncThunk(
  'ExpertiseReviews/updateFeedback',
  async (payload: ExpertiseFeedback) => {
    const { data } = await API.updateFeedback(payload)
    return data
  }
)

export const deleteFeedback = createAsyncThunk(
  'ExpertiseReviews/deleteFeedback',
  async (data: Pick<ExpertiseFeedback, 'id' | 'message'>, { getState }) => {
    const state = getState() as RootState
    const feedback = selectExpertiseFeedbacks(state).find(x => x.id === data.id)
    if (feedback?.feedback_text) {
      await API.deleteFeedback(data)
    }
    return data.id
  }
)
