import { ExpertiseReviewsState } from './types'

export const selectExpertise: AppSelector<ExpertiseReviewsState['expertise']> =
  state => state.expertiseReviews.expertise

export const selectExpertiseMembers: AppSelector<
  ExpertiseReviewsState['expertiseMembers']
> = state => state.expertiseReviews.expertiseMembers

export const selectExpertiseFeedbacks: AppSelector<
  ExpertiseReviewsState['feedbacks']
> = state => state.expertiseReviews.feedbacks

export const selectExpertiseLoading: AppSelector<
  ExpertiseReviewsState['loading']
> = state => state.expertiseReviews.loading
