import { ButtonHTMLAttributes, ReactNode } from 'react'
import { createPortal } from 'react-dom'

import { Button } from 'components'

import styles from './styles.module.scss'

interface ModalProps {
  title?: ReactNode
  children?: ReactNode
}

export const Modal = (props: ModalProps) => {
  const { children, title } = props
  const $el = (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        <h3 className={styles.title}>{title}</h3>
        {children && <div className={styles.content}>{children}</div>}
      </div>
    </div>
  )
  return createPortal($el, document.body)
}

type ButtonType = NonNullable<ButtonHTMLAttributes<HTMLButtonElement>['type']>

export interface ModalConfirmProps extends ModalProps {
  btnCancelText?: ReactNode
  btnConfirmText?: ReactNode
  btnCancelType?: ButtonType
  btnConfirmType?: ButtonType
  onCancel?: () => void
  onConfirm?: () => void
  allowConfirm?: boolean
}

export const ModalConfirm = (props: ModalConfirmProps) => {
  const {
    children,
    title,
    btnCancelText = 'Ні',
    btnConfirmText = 'Так',
    btnCancelType = 'button',
    btnConfirmType = 'button',
    onCancel,
    onConfirm,
    allowConfirm = true,
  } = props

  return (
    <Modal title={title}>
      <div className={styles.confirm_content}>
        {children}
        <div className={styles.confirm_footer}>
          <Button
            type={btnCancelType}
            onClick={onCancel}
            className={styles.btn_cancel}
          >
            {btnCancelText}
          </Button>
          <Button
            type={btnConfirmType}
            onClick={onConfirm}
            className={styles.btn_confirm}
            disabled={!allowConfirm}
          >
            {btnConfirmText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
