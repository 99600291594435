import { Page } from 'components/layouts'

import { ExpertCabinet } from 'features/ExpertCabinet'

export const ExpertCabinetPage = () => {
  return (
    <Page title="Перелік експертиз">
      <ExpertCabinet />
    </Page>
  )
}
