import { groupBy, mapValues } from 'lodash'
import { v4 as uuid } from 'uuid'

import { FILE_DEFAULT_NAME } from 'const'
import {
  ExpertFormDocumentPayload,
  ExpertFormFieldSchema,
  ExpertFormPopulatedDocument,
  ExpertFormValues,
  FormFieldValue,
  FormFieldsGroup,
  FormFieldsSet,
} from 'types'

export function getFieldGroupName(
  field: Pick<ExpertFormFieldSchema, 'screen_group_name' | 'field_name'>
): string {
  const group = field.screen_group_name ?? field.field_name
  return group.replace(/\s|\./g, '_')
}

export function getFieldDefaultValue(
  field: ExpertFormFieldSchema
): FormFieldValue {
  if (field.field_type === 'DOCUMENT') {
    return {
      doc_filename: FILE_DEFAULT_NAME,
      doc_type: '',
      doc_src: 'Заявка', // TODO: get from user status
      doc_name: field.field_name,
      doc_desc: '',
    }
  }
  return ''
}

export function getFieldsGroupDefaultValue(
  group: ExpertFormFieldSchema[]
): FormFieldsGroup {
  const fieldset = group.reduce((m, field) => {
    m[field.field_name] = getFieldDefaultValue(field)
    return m
  }, {} as FormFieldsSet)

  fieldset.id = uuid()

  return [fieldset]
}

/* Set default values for every field, add default entry for every group  */
export function normalizeFormValues(
  schemas: ExpertFormFieldSchema[],
  values: ExpertFormValues
): ExpertFormValues {
  return mapValues(
    groupBy(schemas, getFieldGroupName),
    (group, groupName) => values[groupName] ?? getFieldsGroupDefaultValue(group)
  )
}

export function isDocumentField(
  x: FormFieldValue
): x is ExpertFormDocumentPayload {
  return typeof x === 'object' && 'doc_filename' in x
}

export function isPopulatedDocumentField(
  x: FormFieldValue
): x is ExpertFormPopulatedDocument {
  return isDocumentField(x) && x.doc_file !== undefined
}

export function addFieldsetIds(values: ExpertFormValues): ExpertFormValues {
  return mapValues(values, group =>
    group.map(fieldset =>
      fieldset.id === undefined ? { ...fieldset, id: uuid() } : fieldset
    )
  )
}
