import { createSlice } from '@reduxjs/toolkit'

import { addAsyncThunkReducers } from 'utils'

import * as thunks from './thunks'
import { PersonsRegistrySliceState } from './types'

const initialState: PersonsRegistrySliceState = {
  items: [],
  loading: false,
  error: undefined,
}

export const PersonsRegistrySlice = createSlice({
  name: 'PersonsRegistry',

  initialState,

  reducers: {},

  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.fetchRegistry, 'items')
  },
})
