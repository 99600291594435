import { mapValues, omitBy } from 'lodash'

import api from 'api'

import {
  isArrayFieldName,
  isNullablePseudoBoolFieldName,
  isPseudoBoolFieldName,
} from './lib'
import {
  PersonsRegistryItem,
  RawPersonsRegistryItem,
  RegistryFiltersQuery,
  RegistryFiltersQueryPayload,
} from './types'

const BASE_URL = 'cards/registry'

export const getRegistry = async (
  filters?: Partial<RegistryFiltersQuery>
): Promise<PersonsRegistryItem[]> => {
  const resolvedFilters: typeof filters = mapValues(
    filters,
    composeFilterPayloadValue
  )

  const populatedFilters: RegistryFiltersQueryPayload = omitBy(
    resolvedFilters,
    v => v === ''
  )

  const { data } = await api.get<RawPersonsRegistryItem[]>(BASE_URL, {
    params: populatedFilters,
  })

  return data.map(parseRegistryItem)
}

export const getPersonRecord = async (
  id: string
): Promise<PersonsRegistryItem> => {
  const { data } = await api.get<RawPersonsRegistryItem>(`${BASE_URL}/${id}`)
  return parseRegistryItem(data)
}

function composeFilterPayloadValue(
  v: RegistryFiltersQuery[typeof k],
  k: keyof RegistryFiltersQuery
): RegistryFiltersQueryPayload[typeof k] {
  type RFQ = RegistryFiltersQuery
  type RFP = RegistryFiltersQueryPayload

  // result of `typeof k` inside each `if` is different, accordingly to matching field names

  if (isArrayFieldName(k)) {
    const vv = v as RFQ[typeof k]
    return vv.join(',')
  }

  if (isPseudoBoolFieldName(k)) {
    const vv = v as RFQ[typeof k]
    const res: RFP[typeof k] = vv ? 'Так' : 'Ні'
    return res
  }

  if (isNullablePseudoBoolFieldName(k)) {
    const vv = v as RFQ[typeof k]
    let res: RFP[typeof k]
    if (vv === 'unknown') {
      res = 'Не встановлено'
    } else {
      res = vv ? 'Так' : 'Ні'
    }
    return res
  }

  const vv = v as RFQ[typeof k]
  return vv
}

function parseRegistryItem(x: RawPersonsRegistryItem): PersonsRegistryItem {
  return {
    id: x.id,
    name: x['ПІБ'],
    score: x['ІО'],
    region: x['Область'] ?? '',
    phone: x['Телефон'],
    education: x['ЗВО'] ?? '',
    speciality: x['Спеціальність (провідна)'] ?? '',
    expert_status: x['Статус експерта'] ?? '',
    comment: x['Поточний коментар'],
    // TODO:
    //  This field must never be string.
    //  ExpertForm must be fixed to properly define fields default values.
    //  (for now, it simply sets empty string for every field)
    is_recommended_as_lead: x['РКЕГ'] === '' ? null : x['РКЕГ'],
    expertise_count: x['КПЕ'],
    expertise_count_as_lead: x['КЕК'],
    is_in_expertise: x['ЗЗЕ'],
  }
}
