import React, {
  Suspense as BaseSuspense,
  SuspenseProps,
  useContext,
} from 'react'
import { useErrorHandler as useBaseErrorHandler } from 'react-error-boundary'

import { PartialProps } from 'types/utils'

const DefaultFallback = '...' // TODO: some kind of spinner

export function Suspense(props: PartialProps<SuspenseProps, 'fallback'>) {
  const { fallback = DefaultFallback, ...rest } = props
  const handleError = useBaseErrorHandler()

  return (
    <SuspenseErrorHandlerContext.Provider value={handleError}>
      <BaseSuspense {...rest} fallback={fallback} />
    </SuspenseErrorHandlerContext.Provider>
  )
}

const SuspenseErrorHandlerContext = React.createContext<
  (error: unknown) => void
>(() => {})

export function useSuspenseErrorHandler() {
  return useContext(SuspenseErrorHandlerContext)
}
