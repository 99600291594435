import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { remove } from 'lodash'

import { IUser } from 'features/User'

import { addAsyncThunkReducers } from 'utils'

import * as thunks from './thunks'
import { ExpertiseReviewsState } from './types'

const initialState: ExpertiseReviewsState = {
  expertise: undefined,
  expertiseMembers: [],
  feedbacks: [],
  loading: false,
  error: undefined,
}

interface ExpertiseReviewsAction {
  toExpert: number
  author: Pick<IUser, 'id' | 'email'>
}
export const ExpertiseReviewsSlice = createSlice({
  name: 'ExpertiseReviews',
  initialState,
  reducers: {
    addEmptyFeedback(state, action: PayloadAction<ExpertiseReviewsAction>) {
      const { toExpert, author } = action.payload
      state.feedbacks.unshift({
        id: Date.now(),
        to_expert: toExpert,
        feedback_author_email: author.email,
        feedback_author: author.id,
        approved: true,
      })
    },
    clearExpertise(state) {
      state.expertise = undefined
      state.expertiseMembers = []
      state.feedbacks = []
    },
  },
  extraReducers: builder => {
    addAsyncThunkReducers(
      builder,
      thunks.getExpertiseById,
      (state, { expertise, members }) => {
        state.expertise = expertise
        state.expertiseMembers = members
      }
    )
    addAsyncThunkReducers(builder, thunks.getFeedback, 'feedbacks')
    addAsyncThunkReducers(builder, thunks.addFeedback, (state, data) => {
      remove(state.feedbacks, x => x.id === data.removedId)
      state.feedbacks.unshift(data.feedback)
    })
    addAsyncThunkReducers(builder, thunks.updateFeedback, (state, feedback) => {
      remove(state.feedbacks, x => x.id === feedback.id)
      state.feedbacks.unshift(feedback)
    })
    addAsyncThunkReducers(builder, thunks.deleteFeedback, (state, id) => {
      remove(state.feedbacks, x => x.id === id)
    })
  },
})

export const { addEmptyFeedback, clearExpertise } =
  ExpertiseReviewsSlice.actions
