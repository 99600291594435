import { format } from 'date-fns'

export { shallowEqual } from 'react-redux'
export * from './async'
export * from './redux'

export function normalizeDateInputValue(x: string | Date): string | undefined {
  const d = typeof x === 'string' ? new Date(x) : x
  // invalid date
  if (Number.isNaN(d.valueOf())) {
    console.warn('Got invalid date:', x)
    return undefined
  }
  // format, required by <input type="date"/> : yyyy-MM-dd
  return format(d, 'yyyy-MM-dd')
}

export function formatBool(x: boolean): string {
  return x ? 'Так' : 'Нi'
}
