import { mapValues, pickBy } from 'lodash'

import {
  RegistryFilterField,
  RegistryFilterName,
  RegistryFiltersFormValues,
} from '../types'

export * from './predicates'

/**
 * Conveniently walk over each particular field, regardless of whether it's a single field or fields array.
 *
 * If callback returns undefined, that field will be omitted from resulting structure.
 */
export function traverseFields<R>(
  values: RegistryFiltersFormValues | undefined,
  cb: (
    field: RegistryFilterField,
    name: RegistryFilterName,
    group: RegistryFilterField[]
  ) => R
) {
  const shouldInclude = (x: unknown) =>
    x !== undefined && (Array.isArray(x) ? x.length > 0 : true)

  const result = mapValues(values, (v, k: RegistryFilterName) =>
    Array.isArray(v)
      ? v.map(x => (x === undefined ? x : cb(x, k, v))).filter(shouldInclude)
      : cb(v, k, [v])
  )

  type Result = Exclude<R, undefined>
  return pickBy(result, shouldInclude) as unknown as Record<
    RegistryFilterName,
    Result | Result[]
  >
}

export function canDeactivateField(field: RegistryFilterField) {
  return field.canDisable !== false
}
