import { FieldArray } from 'formik'

import { Button } from 'components'

import { useExpertForm } from '../../../../hooks'
import { resetFields } from '../../lib'

import styles from './styles.module.scss'

export interface RepeatableGroupControlsProps {
  group_name: string
  index: number
}

export function RepeatableGroupControls(props: RepeatableGroupControlsProps) {
  const { group_name, index } = props
  const {
    values: {
      current: { [group_name]: values },
    },
    initialValues: {
      current: { [group_name]: initialValues },
    },
  } = useExpertForm()

  const removable = values.length > 1

  return (
    <FieldArray name={`current.${group_name}`}>
      {({ remove, insert }) => (
        <div className={styles.controls}>
          <Button
            onClick={() => {
              // this relies on fact that we define initial values for every field and every group
              // see features/expertFrom/lib::normalizeFormValues
              const [sample] = initialValues
              insert(0, resetFields(sample))
            }}
          >
            Додати
          </Button>

          {removable && <Button onClick={() => remove(index)}>Видалити</Button>}
        </div>
      )}
    </FieldArray>
  )
}
