import { mapValues, pick, pickBy } from 'lodash'
import * as Yup from 'yup'

import { REQUIRED_EMAIL_ERROR, REQUIRED_FIELD_ERROR } from 'const'
import { ExpertFormValues } from 'types'

export interface FormValues {
  FName: string
  LName: string
  MName: string
  EmailAddr: string
  StudentScientist: string
}

const requiredString = Yup.string().trim().required(REQUIRED_FIELD_ERROR)
export const FormSchema = Yup.object().shape({
  FName: requiredString,
  LName: requiredString,
  MName: requiredString,
  EmailAddr: Yup.string()
    .email(REQUIRED_EMAIL_ERROR)
    .required(REQUIRED_FIELD_ERROR),
  StudentScientist: Yup.string().required('Виберіть один із варіантів'),
})

export const initialValues: FormValues = {
  FName: '',
  LName: '',
  MName: '',
  EmailAddr: '',
  StudentScientist: '',
}

export function getFormPayload(
  values: FormValues,
  fieldGroups: ExpertFormValues
): ExpertFormValues {
  const fieldNames = Object.keys(initialValues)
  const matchingGroups = pickBy(fieldGroups, ([sampleFieldset]) =>
    fieldNames.some(name => name in sampleFieldset)
  )
  return mapValues(matchingGroups, ([sampleFieldset]) => [
    pick(values, Object.keys(sampleFieldset)),
  ])
}
