import { useMemo } from 'react'

import Table from 'components/Table'
import { Bool } from 'components/formatters'

import { useExpertiseTableColumns } from 'features/ExpertiseList'

import { useAppDispatch, useAppSelector, useOnMount } from 'hooks'

import { selectExpertise } from '../../selectors'
import * as thunks from '../../thunks'

export const ExpertiseTable = () => {
  return <Table columns={useColumns()} data={useData()} />
}

function useData() {
  const dispatch = useAppDispatch()

  useOnMount(() => {
    dispatch(thunks.getExpertise())
  })

  return useAppSelector(selectExpertise)
}

function useColumns() {
  const expertiseTableColumns = useExpertiseTableColumns()
  return useMemo(() => {
    const columns = [...expertiseTableColumns]
    columns.splice(5, 0, {
      accessor: 'ExpGroupHead',
      Header: 'Керівник групи?',
      Cell: ({ value }) => <Bool>{value}</Bool>,
      tx: {
        style: {
          maxWidth: 80,
        },
      },
    })
    return columns
  }, [expertiseTableColumns])
}
