import { ErrorMessage as FormikErrorMessage } from 'formik'

import { ErrorMessage } from '../../ErrorMessage'
import { BaseFieldProps, Field } from '../BaseField'
import { Control, stylesControl } from '../Control'

import { FieldInputProps } from './types'

export const FieldInput = (props: FieldInputProps) => {
  const {
    label,
    labelStyle,
    labelClassName,
    containerStyle,
    containerClassName,
    error,
    children,
    ...rest
  } = props

  const { name, type } = rest

  // This typecast is required because of how awful formik (and it's typings) is.
  // Since it's base field inherits props of <input>, <select> and <textarea> at once,
  // you can't just assign to it a `ChangeEventHandler<HTMLInputElement>`, or like that.
  const formikSpreadProps = rest as BaseFieldProps

  return (
    <Control
      type={type}
      label={label}
      labelStyle={labelStyle}
      labelClassName={labelClassName}
      containerStyle={containerStyle}
      containerClassName={containerClassName}
      error={
        /* TODO: some styles with spacing, probably */
        <>
          <FormikErrorMessage name={name}>
            {msg => <ErrorMessage>{msg}</ErrorMessage>}
          </FormikErrorMessage>
          {error}
        </>
      }
    >
      {/* TODO:
            children is only used for textareas now.
            Should refactor those places, pass 'textarea' as `type` prop. */}
      {children ?? (
        <Field className={stylesControl.input} {...formikSpreadProps} />
      )}
    </Control>
  )
}
