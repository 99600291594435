import { useRef } from 'react'

import { useFormikContext } from 'formik'

import { useAppDispatch, useAppSelector, useOnChange } from 'hooks'
import { ExpertFormValuesState } from 'types'

import { useFieldsByTabs } from '../../hooks'
import { setActiveTab } from '../../slice'

export const ErrorScroller = () => {
  const refError = useRef<string>()
  const dispatch = useAppDispatch()
  const { activeTab } = useAppSelector(x => x.expertForm)
  const {
    errors: { current: errors },
    submitCount,
  } = useFormikContext<ExpertFormValuesState>()
  const groups = useFieldsByTabs()

  useOnChange(submitCount, () => {
    const errorField = errors ? Object.keys(errors)[0] : undefined
    if (errorField === undefined) return

    const group = Object.entries(groups).find(([, schemas]) =>
      schemas.some(x => x.field_name === errorField)
    )
    const errorTab = group?.[0]
    if (!errorTab) return

    if (errorTab === activeTab) {
      scrollToInput(errorField)
    } else {
      dispatch(setActiveTab(errorTab))
      refError.current = errorField
    }
  })

  useOnChange(activeTab, () => {
    if (refError.current) {
      scrollToInput(refError.current)
      refError.current = undefined
    }
  })

  return null
}

function scrollToInput(name: string) {
  document
    .querySelector(`input[name$=".${name}"]`)
    ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}
