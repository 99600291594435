import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { AuthRoute, GuestRoute, UserGroup } from 'features/User'

import { Documents } from 'pages/Documents'
import { ExpertCabinetPage } from 'pages/ExpertCabinet'
import { ExpertFormPage } from 'pages/ExpertForm'
import { ExpertiseListPage } from 'pages/ExpertiseList'
import { ExpertiseReviewsPage } from 'pages/ExpertiseReviews'
import { Landing } from 'pages/Landing'
import { Login } from 'pages/Login'
import { PersonsRegistryPage } from 'pages/PersonsRegistry'
import { Registration } from 'pages/Registration'

import routes from 'routes'

export function AppRouter() {
  return (
    <Switch>
      <Route exact path={routes.home}>
        <Landing />
      </Route>

      <GuestRoute path={routes.registration}>
        <Registration />
      </GuestRoute>

      <GuestRoute path={routes.login}>
        <Login />
      </GuestRoute>

      <Route path={routes.documents}>
        <Documents />
      </Route>

      <AuthRoute
        path={routes.registry}
        roles={[UserGroup.expert]}
        rolesMode="deny"
      >
        <PersonsRegistryPage />
      </AuthRoute>

      <AuthRoute path={routes.expertCab} roles={[UserGroup.expert]}>
        <ExpertCabinetPage />
      </AuthRoute>

      <AuthRoute
        exact
        path={routes.expertiseList}
        roles={[UserGroup.expert]}
        rolesMode="deny"
      >
        <ExpertiseListPage />
      </AuthRoute>

      <AuthRoute path={routes.expertise}>
        <ExpertiseReviewsPage />
      </AuthRoute>

      <Route path={routes.form}>
        <ExpertFormPage />
      </Route>

      <Redirect to={routes.home} />
    </Switch>
  )
}
