import { generatePath } from 'react-router-dom'

import { LinkButton } from 'components'
import { Nullable } from 'components/formatters'

import { isExpertUser, useUser } from 'features/User'

import { useAppDispatch, useAppSelector, useOnMount } from 'hooks'
import routes from 'routes'

import { selectScore } from '../../selectors'
import * as thunks from '../../thunks'

import styles from './styles.module.scss'

export const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.header_controls}>
        <ExpertCardLink />
        <IntegralScore />
      </div>
    </div>
  )
}

function ExpertCardLink() {
  const user = useUser()

  if (!isExpertUser(user)) return null

  return (
    <LinkButton
      kind="primary"
      to={generatePath(routes.form, { id: user.card.id })}
    >
      Мої дані (картка експерта)
    </LinkButton>
  )
}

function IntegralScore() {
  const dispatch = useAppDispatch()
  useOnMount(() => {
    dispatch(thunks.getIntegralScore())
  })

  const score = useAppSelector(selectScore)
  return (
    <div className={styles.score}>
      <div>Моя інтегральна оцінка</div>
      <div className={styles.score_value}>
        <Nullable value={score}>{x => Math.round(x)}</Nullable>
      </div>
    </div>
  )
}
