import { goBack, push } from 'connected-react-router'
import { HTMLAttributes } from 'react'
import { matchPath } from 'react-router'

import { Button } from 'components'

import { useAppDispatch, useAppSelector } from 'hooks'

import { selectReferrer } from './slice'

import styles from './styles.module.scss'

interface IGoBackButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'onClick' | 'type'> {
  expect?: string
}

export function GoBackButton(props: IGoBackButtonProps) {
  const { expect, children, ...rest } = props
  const { ref: referrer } = useAppSelector(selectReferrer)
  const dispatch = useAppDispatch()
  return (
    <Button
      kind="bare"
      className={styles.go_back}
      {...rest}
      onClick={() => {
        if (expect === undefined || referrer === null) {
          dispatch(goBack())
          return
        }

        const match = matchPath(referrer.pathname, {
          path: expect,
          exact: true,
        })
        if (match !== null) {
          dispatch(push(referrer))
          return
        }

        dispatch(push(expect))
      }}
    >
      <div className={styles.go_back_content}>
        &#11013;{children && <> {children}</>}
      </div>
    </Button>
  )
}
